<template>
    <h1>{{businessPartner ==  null ? 'Nuovo ' + bpType : 'Modifica ' + bpType }}</h1>
    <div class="business-partner">
        
        <BusinessPartnerDetail :businessPartnerType="$route.params.businessPartnerType" :businessPartnerId="$route.params.businessPartnerId" @entitychange="setEntity"/>
            
    </div>
</template>
<script>
//import {dataService} from '../services/DataService';
import BusinessPartnerDetail from '../components/BusinessPartnerDetail';

export default {
    name : 'BusinessPartner',
     data() {
        return  {
            businessPartner: null,
            businessPartnerType: String,
            bpType: String
        }
     },
    components: {
        'BusinessPartnerDetail' : BusinessPartnerDetail
    },
    mounted() {
    }, 
    activated() {
        this.businessPartnerType = this.$route.params.businessPartnerType;
        this.bpType = this.businessPartnerType == 'C' ? 'Cliente' : 'Fornitore'
    },
    methods: {
        setEntity(bp) {
            console.log('Eccolo!')
            this.businessPartner = bp;
        }
    }
}
</script>