<template>
  <div class="home">
    
      <Bookmarks />
    

  </div>

</template>
<script>
import Bookmarks from '../components/Bookmarks';

export default {
  name: 'Home',
  components: {
    Bookmarks
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
