<template>
    <div>
        <h1>Stagioni</h1>

        <Toolbar class="p-mb-4">
            <template #left>
                <Button label="Nuova stagione" icon="pi pi-plus" class="p-button-success p-mr-2" @click="edit(undefined)" />
            </template>

        </Toolbar>

        <Accordion>
            <AccordionTab header="Filtri">
                <form ref="filterForm" @keyup.enter="filter($event)">
                <div class="p-grid p-jc-center p-mt-2">
                    <div class="p-field p-col-12 p-md-2">
                        <span class="p-float-label">
                            <InputText v-model="filters['id']" id="id"  />
                            <label for="id">Codice</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                        <span class="p-float-label">
                            <InputText v-model="filters['name']" id="name" />
                            <label for="name">Nome</label>
                        </span>
                    </div>
                </div>
                
                <Button ref="filterButton" label="Filtra" @click="filter($event)" class="p-button p-col-12" />
                </form>
            </AccordionTab>
        </Accordion>

        <DataTable ref="dataTable" :loading="dataLoading" :value="items" :lazy="true" :paginator="true" :rows="rows" :autoLayout="true"
            :totalRecords="totalRecords" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" :filters="filters">

            <Column field="id" header="Codice" :sortable="true" filterMatchMode="equals" />
            <Column field="name" header="Descrizione" :sortable="true" filterMatchMode="contains"/>

            <Column headerClass="btn-col" bodyClass="btn-col">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="edit(slotProps.data)" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDelete(slotProps.data)" />
                </template>
            </Column>

        </DataTable>
    </div>

    <Dialog v-model:visible="editDialog" :style="{width: '450px'}" :header="(item?.id ? 'Modifica stagione' : 'Nuova stagione')" :modal="true" :closable="false" :closeOnEscape="false" class="p-fluid">
        <div class="p-field">
            <label for="id">Codice</label>
            <InputText id="id" v-model.trim="item.id" required="true" autofocus :class="{'p-invalid': submitted && !item.id}" :disabled="!isNew" />
            <small class="p-invalid" v-if="submitted && !item.id">Campo obbligatorio</small>
        </div>
        <div class="p-field">
            <label for="name">Nome</label>
            <InputText id="name" v-model="item.name" required="true" autofocus :class="{'p-invalid': submitted && !item.name}" />
            <small class="p-invalid" v-if="submitted && !item.name">Campo obbligatorio</small>
        </div>
        <template #footer>
            <Button label="Annulla" icon="pi pi-times" class="p-button-text" @click="hide"/>
            <Button label="Salva" icon="pi pi-check" class="p-button-text" @click="save" />
        </template>
    </Dialog>

    <Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Conferma" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            <span v-if="item">Confermi la cancellazione della stagione <b>{{item.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
            <Button label="Sì" icon="pi pi-check" class="p-button-text" @click="deleteItem" />
        </template>
    </Dialog>

</template>
<script>
import {parametersFromEvent} from '../Utils';
import {dataService} from '../services/DataService';

export default {
    data() {
        return {
            isNew: false,
            items: [],
            item: {},
            //originalObject: {},
            rows: this.defaultRows,
            totalRecords: 0,
            dataLoading: false,
            filters: {},
            params: new URLSearchParams(),
            editDialog: false,
            deleteDialog: false,
            submitted: false,
            currentOffset: 0,
            itemGQL : 
            `id
            name`
        }
    },
    setup() {
    },
    activated()  {
        this.doSearch(this.currentOffset);
    },
    methods: {
        doSearch(offset) {
            this.dataLoading = true;
            dataService.searchGraphQLAPI('searchSeasons', this.params, this.itemGQL, offset, this.rows)
            .then(res => {
                this.items = res.elements;
                this.totalRecords = res.rowCount;
                this.dataLoading = false;
                this.currentOffset = offset;
            })
            .catch(error => {
                this.dataLoading = false;
                this.$swal({
                    icon: 'error',
                    title:  'Errore',
                    text: error.response?.data.message,
                });               
            });
        },
        onPage(event) {
            this.doSearch(event.first);
        },
        onSort(event) {
          //console.log(event);
          this.params = parametersFromEvent(event);
          this.doSearch(0);
        },
        filter(event) {
          const dt = this.$refs.dataTable;
          dt.$emit('filter', dt.createLazyLoadEvent(event));
          dt.resetPage();
        },
        onFilter(event) {
            this.params = parametersFromEvent(event);
            this.doSearch(0);
        },
        confirmDelete(i) {
            this.item = i;
            this.deleteDialog = true;
        },
        deleteItem() {
            dataService.delete('/api/seasons/' + this.item.id)
                .then(() => {
                    this.$toast.add({severity:'success', summary: 'Successo', detail: 'Stagione cancellata', life: 3000});
                    this.deleteDialog = false;
                    const dt = this.$refs.dataTable;
                    dt.$emit('filter', dt.createLazyLoadEvent(event));
                })
                .catch(error => {
                    this.$swal({
                        icon: 'error',
                        title:  'Errore',
                        text: error.response?.data.message,
                    });  
                })
            
        },
        edit(o) {
            var workingObject = {};
            Object.assign(workingObject, o)
            if (o != undefined) {
                this.isNew = false;
                this.item =  workingObject;
                this.submitted = false;
                this.editDialog = true;
            } else {
                this.item = {};
                this.isNew = true;
                this.submitted = false;
                this.editDialog = true;
            }
        },
        hide() {
            //Object.assign(this.item, this.originalObject)
            this.editDialog = false;
        },
        isValid() {
            return this.item.name && this.item.id;
        },
        save() {
            this.submitted = true;
            if (this.isValid()) {
                this.$root.loading = true;
                const self = this;
                dataService.post('/api/seasons', this.item)
                .then(res => {
                    this.$root.loading = false;
                    this.item = res.data;
                    this.$toast.add({severity:'success', summary: 'Successo', detail: 'Stagione salvata correttamente', life: 3000});
                    this.editDialog = false;
                    this.doSearch(this.currentOffset);
                })
                .catch(error => {
                    Object.assign(this.item, this.originalObject);
                    self.$root.loading = false;
                    //this.editDialog = false;
                    this.$swal({
                        icon: 'error',
                        title: 'Errore',
                        text: error.response?.data.message,
                    });               
                });
            }
            
        }
    }
}
</script>