//import Vue from 'vue'
import { createWebHistory, createRouter } from "vue-router";
import Home from '../views/Home.vue'
import ProductStocks from '../views/ProductStocks.vue'
import ProductStock from '../views/ProductStock.vue'
import Products from '../views/Products.vue'
import Product from '../views/Product.vue'
import Documents from '../views/Documents.vue'
//import POSDocuments from '../views/POSDocuments.vue'
import Document from '../views/Document.vue'
import BusinessPartners from '../views/BusinessPartners.vue'
import BusinessPartner from '../views/BusinessPartner.vue'
import StatStock from '../views/StatStock.vue'
import Brands from '../views/Brands.vue'
import Seasons from '../views/Seasons.vue'
import Colors from '../views/Colors.vue'
import ProductTypes from '../views/ProductTypes.vue'
//import ProductTypes from '../views/ProductTypes.vue'
import DocumentItems from '../views/DocumentItems.vue'
import Login from '../views/Login.vue'
import { dataService } from '../services/DataService';

//Vue.use(Router)

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  base: process.env.BASE_URL,  
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },{
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
          allowAnonymous: true
        }
      },
    {
        path: '/productstocks',
        name: 'productstocks',
        component: ProductStocks
    },
    {
        path: '/productstocks/:productId',
        name: 'productstock',
        component: ProductStock
    },
    {
      path: '/products',
      name: 'products',
      component: Products
  },
  {
      path: '/products/:productId',
      name: 'product',
      component: Product
  },
    {
        path: '/product',
        name: 'newProduct',
        component: Product
    },
/*    
    {
      path: '/documents/U00',
      name: 'u00-documents',
      component: POSDocuments,
      props: () => ({
        stockReasonId: 'U00'
      })
    },
    {
      path: '/documents/U10',
      name: 'u10-documents',
      component: POSDocuments,
      props: () => ({
        stockReasonId: 'U10'
      })
    },
*/    
    {
      path: '/documents/:stockReasonId',
      name: 'documents',
      component: Documents
    },
    {
      path: '/businesspartners/:businessPartnerType',
      name: 'business-partners',
      component: BusinessPartners
    },
    {
      path: '/businesspartners/:businessPartnerType/new',
      name: 'new-business-partner',
      component: BusinessPartner
    },
    {
      path: '/businesspartners/:businessPartnerType/:businessPartnerId',
      name: 'business-partner',
      component: BusinessPartner
    },
    {
      path: '/documents/:documentTypeId/:documentNumber',
      name: 'document',
      component: Document
    },
    {
      path: '/documents/:documentTypeId/new',
      name: 'newDocument',
      component: Document
    },
    {
      path: '/brands',
      name: 'brands',
      component: Brands
    },
    {
      path: '/seasons',
      name: 'seasons',
      component: Seasons
    },
    {
      path: '/colors',
      name: 'colors',
      component: Colors
    },
    {
      path: '/producttypes',
      name: 'producttypes',
      component: ProductTypes
    },
    {
      path: '/stats/stock',
      name: 'stats_stock',
      component: StatStock
    },
    /*
    {
      path: '/producttypes',
      name: 'producttypes',
      component: ProductTypes
    },*/
    {
      path: '/documentitems/:documentTypeId',
      name: 'document-items',
      component: DocumentItems
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (!to.meta.allowAnonymous) {
    dataService.isLoggedIn().then(isLoggedIn => {

      if (!isLoggedIn) {
        
        next({
          path: '/login',
          query: { redirect: to.fullPath },
          replace: true
        })
      
      } else {
        next()
      }  
    });
  } else {
    next();
  }
})

export default router;