<template>
    <div id="document-detail" class="p-mt-2">

        <h1>{{document.documentType?.id}} {{document.documentType?.name}} {{document.id?.number}}</h1>

        <TabView class="p-mb-4" v-model:activeIndex="activeIndex">

            <TabPanel header="Testata">
                <div class="p-fluid p-grid">
                    <div class="p-col-12">
                        <Card>
                            <template v-slot:header>
                                <span>Dati generali</span>
                            </template>
                            <template v-slot:content>
                                <div class="p-fluid p-grid">
                                    <div class="p-field p-col-12 p-md-3">
                                        <span class="p-float-label">
                                           <Date v-model="document.date" :disabled="!isEditable" />
                                           <label for="date">Data documento</label>
                                        </span>
                                    </div>
                                    <div class="p-field p-col-12 p-md-3">
                                        <!--
                                        <span class="p-float-label">
                                            <Dropdown v-model="document.warehouse" :options="warehouseList" :filter="(warehouseList.length > 5)" @show="loadWarehouses()" optionLabel="id" :disabled="!isEditable" >
                                                <template #value="slotProps">
                                                    {{slotProps.value?.id}} - {{slotProps.value?.name}}
                                                </template>
                                                <template #option="slotProps">
                                                    <div v-if="slotProps.option">
                                                    {{slotProps.option?.id}} - {{slotProps.option?.name}}
                                                    </div>
                                                </template>
                                            </Dropdown>
                                            <label for="warehouse">Magazzino</label>
                                        </span>
                                        -->
                                        <Warehouse v-model="document.warehouse" />
                                    </div>
                                    <div class="p-field p-col-12 p-md-3">
                                        <span class="p-float-label">
                                            <Dropdown v-model="document.season" :options="seasonList" :filter="(seasonList.length > 5)" @show="loadSeasons()" optionLabel="id" :disabled="!isEditable" :showClear="true">
                                                <template #value="slotProps">
                                                    {{slotProps.value?.id}} - {{slotProps.value?.name}}
                                                </template>
                                                <template #option="slotProps">
                                                    <div v-if="slotProps.option">
                                                    {{slotProps.option?.id}} - {{slotProps.option?.name}}
                                                    </div>
                                                </template>
                                            </Dropdown>
                                            <label for="season">Stagione</label>
                                        </span>
                                    </div>

                                    <div class="p-field p-col-12 p-md-3">
                                        <span class="p-float-label">
                                            <Dropdown id="status" v-model="document.status" :options="statusList" :filter="(statusList.length > 5)" @show="loadStatuses()" optionLabel="id" optionValue="id" :disabled="!isEditable" >
                                                <template #option="slotProps">
                                                    <div v-if="slotProps.option">
                                                    {{slotProps.option?.id}} - {{slotProps.option?.name}}
                                                    </div>
                                                </template>
                                            </Dropdown>
                                            <label for="status">Stato</label>
                                        </span>
                                    </div>

                                    <div class="p-field p-col-4 p-md-1">
                                        <span class="p-float-label">
                                            <InputText id="refDocumentType" v-model="document.refDocumentType" />
                                            <label for="refDocumentType">Tipo Rif.</label>
                                        </span>
                                    </div>

                                    <div class="p-field p-col-8 p-md-3">
                                        <span class="p-float-label">
                                            <InputText id="refDocumentNumber" v-model="document.refDocumentNumber" />
                                            <label for="refDocumentNumber">Numero Rif.</label>
                                        </span>
                                    </div>

                                    <div class="p-field p-col-12 p-md-4">
                                        <span class="p-float-label">
                                            <InputText id="orderNumber" v-model="document.orderNumber" />
                                            <label for="orderNumber">Riferimento ordine</label>
                                        </span>
                                    </div>

                                    <div class="p-field p-col-12 p-md-4">
                                        <span class="p-float-label">
                                            <Date id="orderDate" v-model="document.orderDate" />
                                           <label for="orderDate">Data ordine</label>
                                        </span>
                                    </div>
                                    

                                </div>
                            </template>
                        </Card>
                    </div>    
                    <div class="p-col-12 p-md-6">
                        <Card style="height:100%"  v-if="document.documentType?.businessPartnerType != 'L'">
                            <template v-slot:header>
                                <span v-if="document.documentType?.businessPartnerType == 'F'">Fornitore</span>
                                <span v-if="document.documentType?.businessPartnerType == 'C'">Cliente</span>
                            </template>
                            <template v-slot:content>
                                
                                <span class="p-float-label">
                                    <Dropdown id="bp" v-model="document.businessPartner" :options="addressList" :filter="(addressList.length > 5)" @show="loadAddresses()" optionLabel="lastName" :disabled="!isEditable">
                                        <template #value="slotProps">
                                            <span>{{slotProps.value?.id}}&nbsp;</span>
                                        </template>
                                        <template #option="slotProps">
                                            <div v-if="slotProps.option">
                                            {{slotProps.option?.lastName}} {{slotProps.option?.firstName}} {{slotProps.option?.street}} {{slotProps.option?.city}} {{slotProps.option?.province}} 
                                            </div>
                                        </template>
                                    </Dropdown>
                                    <label for="bp">Business partner</label>
                                </span>
                                
                                
                                <div class="p-pt-3"><strong>{{document.businessPartner?.lastName}} {{document.businessPartner?.firstName}}</strong></div>
                                <div class="p-pt-1">{{document.businessPartner?.street}}</div>
                                <div class="p-pt-1">{{document.businessPartner?.zipCode}} {{document.businessPartner?.city}} {{document.businessPartner?.province}}</div>
                                <div class="p-pt-1" v-if="document.businessPartner?.vatCode">P.IVA: {{document.businessPartner?.vatCode}}</div>
                                <div class="p-pt-1" v-if="document.businessPartner?.fiscalCode">C.F.: {{document.businessPartner?.fiscalCode}}</div>

                            </template>
                        </Card>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <Card style="height:100%">
                            <template v-slot:header>
                                <span>Destinazione</span>
                            </template>
                            <template v-slot:content>
                            <div class="p-field-checkbox">
                                    <Checkbox  v-model="shipToBill" :binary="true" />
                                    <label for="binary">Uguale all'indirizzo di fatturazione</label>
                                </div>
                                <div class="p-field">
                                    <span class="p-float-label">
                                        <InputText id="shName" type="text" v-model="document.shName" :disabled="shipToBill || !isEditable" />
                                        <label for="shName">Nome</label>
                                    </span>
                                </div>
                                <div class="p-field">
                                    <span class="p-float-label">
                                        <InputText id="shStreet" type="text" v-model="document.shStreet" :disabled="shipToBill || !isEditable" />
                                        <label for="shStreet">Indirizzo</label>
                                    </span>
                                </div>
                                <div class="p-field">
                                    <span class="p-float-label">
                                        <InputText id="shCity" type="text" v-model="document.shCity" :disabled="shipToBill || !isEditable" />
                                        <label for="shCity">CAP/Comune/Provincia</label>
                                    </span>
                                </div>
                            </template>
                        </Card>
                    </div>

                </div>

            </TabPanel>

            <TabPanel header="Righe">
                <!--
                <DocumentItemList v-if="document.id" v-bind:document="document" :key="componentKey" />
                -->
                <DocumentProductList v-if="document.id" v-bind:document="document" :key="componentKey" />
            </TabPanel>

            <TabPanel header="Piede">
                <div class="p-fluid p-grid p-mt-3">
                    
                    <div class="p-field p-col-12 p-lg-3">
                        <TransportReason v-model="document.transportReason" :showClear="true" />
                    </div>
                    <div class="p-field p-col-12 p-lg-3">
                        <Transport v-model="document.transport" :showClear="true" />
                    </div>
                    <div class="p-field p-col-12 p-lg-3">
                        <Carrier v-model="document.carrier" :showClear="true" />
                    </div>
                    <div class="p-field p-col-12 p-lg-3">
                        <Carriage v-model="document.carriage" :showClear="true" />
                    </div>
                    <div class="p-field p-col-8 p-lg-2">
                        <span class="p-float-label">
                            <Date id="transportDate" v-model="document.transportDate" :disabled="!isEditable" />
                            <label for="transportDate">Data trasporto</label>
                        </span>
                    </div>
                    <div class="p-field p-col-4 p-lg-1">
                        <span class="p-float-label">
                            <InputMask id="transportTime" v-model="document.transportTime" mask="99:99" slotChar="HH:mm" />
                            <label for="transportTime">Ora trasporto</label>
                        </span>
                    </div>
                    <div class="p-field p-col-8 p-lg-2">
                        <Appearance v-model="document.appearance" :showClear="true" />
                    </div>
                    <div class="p-field p-col-4 p-lg-1">
                        <span class="p-float-label">
                            <InputNumber id="numberOfPackages" v-model="document.numberOfPackages" />
                            <label for="numberOfPackages">Numero colli</label>
                        </span>
                    </div>

                    <div class="p-field p-col-12 p-lg-3">
                        <PaymentType v-model="document.paymentType" :showClear="true" />
                    </div>

                     <div class="p-field p-col-12">
                        <span class="p-float-label">
                            <InputText id="notes" v-model="document.notes" />
                            <label for="notes">Note</label>
                        </span>
                    </div>

                </div>

            </TabPanel>    

        </TabView>    

        <div>
            <div class="action-bar">
                <Button icon="pi pi-arrow-left" label="Indietro" @click="this.$router.go(-1)" />
                <Button icon="pi pi-save" label="Salva" @click="saveDocument" />
                <Button icon="fas fa-bolt" label="Consolida" @click="consolidate(false)" v-if="document.documentType?.stockReason.id == 'INV'"/>
                <Button icon="pi pi-print" label="Stampa" @click="printDocument()" v-if="document.documentType?.reportTemplate" />
                <Button icon="fas fa-tags" label="Etichette" @click="printLabels" v-if="document.documentType?.stockReason.id == 'CAR' || document.documentType?.stockReason.id == 'INV'" />
            </div>
        </div>

    </div>

    <DocumentLabels :document="document" v-model:show="documentLabelsDialog" />
    
    <Dialog v-model:visible="confirmConsolidateDialog" :style="{width: '450px'}" header="Conferma consolidamento" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            <span>L'operazione di consolidamento generarà i movimenti di magazzino e le righe inserite nel presente documento non saranno più modificabili. Proseguire?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="confirmConsolidateDialog = false"/>
            <Button label="Sì" icon="pi pi-check" class="p-button-text" @click="consolidate(true)" />
        </template>
    </Dialog>

    <Dialog v-model:visible="chooseTemplate" :style="{width: '450px'}" header="Scelta template" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            <span>Vuoi che nel documento vengano riportati gli importi?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="printDocument(null, true)"/>
            <Button label="Sì" icon="pi pi-check" class="p-button-text" @click="printDocument('Invoice', true)" />
        </template>
    </Dialog>
</template>
<script>
import {dataService} from '../services/DataService';
import {formatAmount, formatDate, it, parseDate} from '../Utils';
import DocumentProductList from './DocumentProductList';
import DocumentLabels from './DocumentLabels';
import TransportReason from './input/TransportReason';
import Transport from './input/Transport';
import Carrier from './input/Carrier';
import Carriage from './input/Carriage';
import Appearance from './input/Appearance';
import PaymentType from './input/PaymentType';

export default {
    name: 'DocumentDetail',
    props: {
        documentTypeId : String,
        documentNumber: String //this.$route.params.documentNumber
    },
    components: {
        'DocumentProductList': DocumentProductList,
        'DocumentLabels': DocumentLabels,
        'TransportReason': TransportReason,
        'Transport': Transport,
        'Carrier': Carrier,
        'Carriage': Carriage,
        'Appearance': Appearance,
        'PaymentType': PaymentType,
    },
    data() {
        return  {
            total: 0.0,
            activeIndex: 0,
            document: {}, //this.createDocument(),
            isEditable: true,
            statusList: [],
            addressList: [],
            warehouseList : [],
            seasonList : [],
            colorList: [],
            sizeList: [],
            productList: [],
            appearanceList: [],
            transportReasonList: [],
            transportList: [],
            carrierList: [],
            carriageList: [],
            paymentTypeList: [],
            it: it,
            submitted: false,
            shipToBill: true,
            componentKey: 0,
            documentLabelsDialog: false,
            confirmConsolidateDialog: false,
            chooseTemplate: false,
        }
    },
    created() {
        console.log('DocumentDetail --> created');
    },
    mounted() {
        console.log('DocumentDetail --> mounted');
    },
    activated() {
        
        this.activeIndex = 0;
        this.document = {};
        this.$root.loading = true;
        this.isEditable= true;
        const self = this;
        if (this.documentTypeId && this.documentNumber) {
            dataService.getDocument(this.documentTypeId, this.documentNumber).then(data => {
                self.document = data;
                self.document.date = parseDate(self.document.date);
                self.document.transportDate = parseDate(self.document.transportDate);
                //self.isEditable = data.status != 'X';
                self.shipToBill =  !self.document.shStreet;
                this.forceUpdate();     
                this.$root.loading = false;           
            });
        } else {
            this.document = this.createDocument();
        }
        dataService.searchWarehouses(new URLSearchParams('sortProperty=id&sortOrder=1'), 0, 1000).then(data => {this.warehouseList = data.elements});
      
    },
    methods: {
        formatAmount,
        formatDate,
        forceUpdate() {
            this.componentKey++;
        },
        createDocument() {
            this.total = 0;
            return dataService.newDocument(this.documentTypeId, localStorage.getItem('year')).then(res => {
                res.date = new Date();
                res.warehouse = JSON.parse(localStorage.getItem("warehouse"));
                this.document = res;
                this.document.date = new Date();
                this.$root.loading = false;
                this.forceUpdate();
            });
        },
        
        computeTotal() {
            this.total = 0;
            for (var i = 0; i < this.documentItems.length; i++) {
                this.total += this.documentItems[i].total;
                //console.log(this.documentItems[i].id.prog + " - " + this.documentItems[i].total);
            }
            console.log('Total: ' + this.total);
        },
        loadStatuses() {
            if (this.statusList.length == 0)
                dataService.listStatuses().then(data => {this.statusList = data.elements});
        },
        loadAddresses() {
            if (this.addressList.length == 0) {
                if (this.document.documentType.businessPartnerType == 'F')
                    dataService.listSuppliers().then(data => {this.addressList = data.elements});
                else
                    dataService.listCustomers().then(data => {this.addressList = data.elements});
            }
        },
        loadWarehouses() {
            if (this.warehouseList.length == 0)
                dataService.listWarehouses().then(data => {this.warehouseList = data.elements})
        },
        loadSeasons() {
            if (this.seasonList.length == 0)
                dataService.listSeasons().then(data => {this.seasonList = data.elements});
        },
        loadAppearances() {
            if (this.appearanceList.length == 0)
                dataService.listAppearances().then(data => {this.appearanceList = data.elements});
        },
        loadTransportReasons() {
            if (this.transportReasonList.length == 0)
                dataService.listTransportReasons().then(data => {this.transportReasonList = data.elements});
        },
        loadTransports() {
            if (this.transportList.length == 0)
                dataService.listTransports().then(data => {this.transportList = data.elements});
        },
        loadCarriers() {
            if (this.carrierList.length == 0)
                dataService.listCarriers().then(data => {this.carrierList = data.elements});
        },
        loadCarriages() {
            if (this.carriageList.length == 0)
                dataService.listCarriages().then(data => {this.carriageList = data.elements});
        },
        loadPaymentTypes() {
            if (this.paymentTypeList.length == 0)
                dataService.listPaymentTypes().then(data => {this.paymentTypeList = data.elements});
        },
        printDocument(reportTemplate, force) {
            if (!force && this.document.documentType.reportTemplate == 'DDT') {
                this.chooseTemplate = true;
            } else {
                this.chooseTemplate = false
                location.href =  process.env.VUE_APP_BASEURL + '/api/documents/' + this.document.id.documentTypeId + '/' + this.document.id.number + '/print' + (reportTemplate ? '?reportTemplate=' + reportTemplate : '');
            }
        },
        printLabels() {
            this.documentLabelsDialog = true;
        },
        saveDocument() {
            this.$root.loading = true;
            var year = Number(localStorage.getItem("year"));
            console.log(year);
            this.document.year =  year;
            
            if (this.document.documentType.stockReason.id == 'INV') {
                dataService.storeDocument(this.document).then(() => {
                    this.$root.loading = false;
                    this.$toast.add({severity:'success', summary: 'OK', detail: 'Documento salvato correttamente', life: 5000});
                    this.$router.go(-1);
                })
                .catch(error => {
                    this.$root.loading = false;
                    //this.$toast.add({severity:'error', summary: 'Errore', detail: error, life: 5000});
                    this.$swal({
                        icon: 'error',
                        title: 'Errore',
                        text: error.response?.data.message,
                    });
                    console.log(error.response?.data.message);
                
                });
            } else {
                this.consolidate(true);
            }
        },
        consolidate(force) {
            if (force) {
                this.$root.loading = true;
                dataService.consolidateDocument(this.document).then(() => {
                        this.$root.loading = false;
                        this.confirmConsolidateDialog = false;
                        this.$toast.add({severity:'success', summary: 'OK', detail: 'Documento salvato correttamente', life: 5000});
                        this.$router.go(-1);
                    })
                    .catch(error => {
                        this.$root.loading = false;
                        this.confirmConsolidateDialog = false;
                        //this.$toast.add({severity:'error', summary: 'Errore', detail: error, life: 5000});
                        this.$swal({
                            icon: 'error',
                            title: 'Errore',
                            text: error.response?.data.message,
                        });
                        console.log(error.response?.data.message);
                    
                    });
            } else {
                this.confirmConsolidateDialog = true;
            }
        }
    }
}

</script>