<template>
  <div class="document-list">
   
    <h1>{{stockReason.name}}</h1>

    <Toolbar class="p-mb-4">
       <template v-slot:left>
        <Button label="Nuovo documento" icon="pi pi-plus" class="p-button-success p-mr-2" @click="newDocument()" />
      </template>
      <template v-slot:right>
        <!--
        <Button label="Invia venduto" icon="pi pi-upload" class="p-button-warning p-mr-2" @click="sendDocuments()" v-if="stockReasonId == 'U10'"/>
        -->
      </template>
    </Toolbar>
  
  <DocumentsDT :value="documents" :rows="rows" :totalRecords="totalRecords" :loading="loading" :bpType="bpType" :documentTypes="documentTypes" :filters="filters"
      @page="onPage($event)" @sort="onSort($event)"  @filter="onFilter($event)" @editdocument="editDocument($event)" />

  <Dialog v-model:visible="yearConfirmationDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            <span>L'anno corrente non corrisponde all'anno di esercizio. Vuoi creare il documento per l'anno {{year}}?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="yearConfirmationDialog = false"/>
            <Button label="Sì" icon="pi pi-check" class="p-button-text" @click="forceNewDocument()" />
        </template>
    </Dialog>

  <Dialog header="Scegli il tipo di documento" v-model:visible="showDocumentTypeChooseDialog" :modal="true">
    <Dropdown v-model="selectedDocumentType" :options="documentTypes" >
      <template #value="slotProps">
          <div v-if="slotProps.value">
          {{slotProps.value?.id}} - {{slotProps.value?.name}}
          </div>
          <div v-else>&nbsp;</div>
      </template>
        <template #option="slotProps">
          <div v-if="slotProps.option">
          {{slotProps.option?.id}} - {{slotProps.option?.name}}
          </div>
      </template>      
    </Dropdown>
    <template #footer>
        <Button label="OK" icon="pi pi-check" class="p-button-text" @click="newDocument(selectedDocumentType.id, true)" />
    </template>
  </Dialog>

  </div>

</template>

<script>
import {dataService} from '../services/DataService';
import {parametersFromEvent, formatDate, formatAmount} from '../Utils';
import DocumentsDT from '../components/DocumentsDT';

export default {
    name: 'Documents',
    components: {
      DocumentsDT
    },
    data() {
        console.log('data');
        return {
            year: localStorage.getItem('year'),
            loading: false,
            totalRecords: 0,
            documents: null,
            rows: this.defaultRows,
            filters: {},
            params: new URLSearchParams(),
            sortField: String,
            sortOrder: Number,
            stockReason : Object,
            documentTypes: null,
            docTypeDisplay: '',
            bpType : null,
            showDocumentTypeChooseDialog : false,
            yearConfirmationDialog: false,
            selectedDocumentType : {},
            currentOffset: 0,
        }
    },
    created() {
      console.log('Documents --> created');
    },
    mounted() {
      console.log('Documents --> mounted');
      //const dt = this.$refs.documentDataTable;
      //dt.sortField = 'date';
      //dt.sortOrder = -1;
      //this.currentOffset = 0;
    },
    //params : new URLSearchParams()
    activated() {
        console.log('Documents --> activated');
        this.stockReasonId = this.$route.params.stockReasonId;

        dataService.getStockReason(this.stockReasonId)
          .then(res => {
            this.stockReason = res
            console.log('Stock reason', this.stockReason);
          });
        
        this.params = new URLSearchParams();
        this.params.set('documentType.stockReason.id', this.stockReasonId);
        this.params.set('sortProperty', 'id.number');
        this.params.set('sortOrder', 'desc');
        this.filters['documentType.stockReason.id'] = this.stockReasonId;

        this.doSearch(this.currentOffset);

        dataService.searchDocumentTypes(new URLSearchParams('stockReason.id='+this.stockReasonId), 0, 100).then(res => {
          this.documentTypes = res.elements
          this.docTypeDisplay = this.documentTypes.length < 2 ? 'hidden' : ''
          switch (this.documentTypes[0].businessPartnerType) {
            case 'C':
              this.bpType = 'Cliente';
              break;
            case 'F':
              this.bpType = 'Fornitore';
              break;
            default:
              this.bpType = 'Soggetto';
          }
        });

        
  
    },
    methods: {
        formatDate,
        formatAmount,
        doSearch(offset) {
          this.loading = true;
          dataService.searchDocuments(this.params, offset, this.rows).then(data => {
                this.totalRecords = data.rowCount,
                this.documents = data.elements
                this.loading = false;
                this.currentOffset = offset;
            });
        },
        onPage(event) {
            this.params = parametersFromEvent(event);
            this.doSearch(event.first);
        },
        onFilter(event) {
          console.log(event);
          this.filters = event.filters;
          this.params = parametersFromEvent(event);
          this.doSearch(0);
        },
        onSort(event) {
          console.log(event);
          this.params = parametersFromEvent(event);
          console.log(this.params);
          this.doSearch(0);
        },
        forceNewDocument() {
          this.yearConfirmationDialog = false;
          this.newDocument(this.documentTypeId, true);
        },
        newDocument(documentTypeId, force) {
          var now = new Date();
          if (now.getFullYear() != localStorage.getItem('year') && !force) {
              this.yearConfirmationDialog = true;
              return false;
          }
          this.showDocumentTypeChooseDialog=false;
          if (documentTypeId == null) {
            if (this.documentTypes.length > 1) {
              console.log('Apri sclelta');
              this.showDocumentTypeChooseDialog = true;
            } else {
              this.newDocument(this.documentTypes[0].id, force);
            }
          } else {
            console.log('--> ' + documentTypeId);
            this.$router.push({ name: 'newDocument', params: { documentTypeId: documentTypeId}});
          }
          
        },
        editDocument(doc) {
          this.$router.push({ name: 'document', params: { documentTypeId: doc.id.documentTypeId, documentNumber: doc.id.number }});
        },
        sendDocuments() {
          this.$root.loading = true;
          dataService.sendDocuments(this.documentTypes[0].id).then(res => {
            this.$swal({
                    icon: (res > 0 ? 'info' : 'warning'),
                    title: (res > 0 ? 'Successo' : 'Attenzione'),
                    text: (res > 0 ? 'Sono stati inviati ' + res + ' documenti' : 'Non ci sono documenti da inviare')
                });
            this.$root.loading = false;
          })
          .catch(error => {
                //this.$toast.add({severity:'error', summary: 'Errore', detail: error, life: 5000});
                this.$swal({
                    icon: 'error',
                    title: 'Errore',
                    text: error.response?.data.message,
                });
                console.log(error.response?.data.message);
               this.$root.loading = false;
          });
        }
    }
}
</script>