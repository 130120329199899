<template>

        <Accordion>
            <AccordionTab header="Filtri">
                <form ref="filterForm"  @keyup.enter="filter($event)">
                <div class="p-grid p-jc-center p-mt-2">
                    <div class="p-field p-col-12 p-md-2">
                        <span class="p-float-label">
                            <InputText v-model="filters['product.partnumber']" id="partnumber" />
                            <label for="partnumber">Codice</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <span class="p-float-label">
                            <InputText v-model="filters['product.name']" id="name" />
                            <label for="name">Descrizione</label>
                        </span>
                    </div>
                </div>        
                <Button ref="filterButton" label="Filtra" @click="filter($event)" class="p-button p-col-12" />
                </form>
            </AccordionTab>
        </Accordion>

        <DataTable ref="dataTable" :value="documentItems" :lazy="true" :paginator="true" :rows="rows" :autoLayout="true" :scrollable="true" scrollHeight="flex" 
            :totalRecords="totalRecords" :filters="filters" :loading="loading" @page="onPage($event)" @sort="onSort($event)"  @filter="onFilter($event)">
            
            <Column field="id.number" header="Documento" :sortable="true"  filterMatchMode="contains">
                 <template #body="slotProps">
                     {{slotProps.data.document.id.number}}<br/>
                      {{formatDate(slotProps.data.document.date)}}
                 </template>
            </Column>

            <Column field="product.name" header="Descrizione" :sortable="true" :headerStyle="{width: '20%'}" filterMatchMode="contains" />
            
            <Column field="product.partnumber" header="Codice" :sortable="true"  filterMatchMode="contains" />

            <Column field="color" header="Colore" :sortable="true"  filterMatchMode="contains">
                <template #body="slotProps">
                    {{slotProps.data.color.id}} - {{slotProps.data.color.name}}
                </template>
            </Column>

            <Column field="size" header="Taglia" :sortable="true"  filterMatchMode="contains">
                <template #body="slotProps">
                    {{slotProps.data.size.id}}
                </template>
            </Column>

            <Column field="quantity" header="Quantità" :sortable="true" />

            <Column field="quantityProcessed" header="Evasa" :sortable="true" />

            <Column field="quantityToProcess" header="Da evadere" bodyStyle="font-weight: bold" :sortable="true" />

            <Column header="Quantità" :headerStyle="{width: '100px'}">
                <template #body="slotProps">
                    <InputNumber v-model="slotProps.data.q" style="width: 50px" />
                </template>
            </Column>

            <Column bodyClass="p-text-right" :headerStyle="{width: '80px'}">
                <template #body="slotProps">
                    <Button icon="pi pi-plus" class="p-button-rounded p-button-success p-mr-2" @click="process(slotProps.data)" v-if="slotProps.data.quantityToProcess > 0" />
                </template>
            </Column>

        </DataTable>
    
</template>
<script>
import {dataService} from '../services/DataService';
import {parametersFromEvent, formatDate, formatAmount} from '../Utils';

export default {
    name: 'DocumentItemList',
    props: {
        targetDocument: Object,
    },
    emits: ["added"],
    data() {
        return {
            documentTypeId: this.targetDocument.documentType.parent.id,
            businessPartnerType: this.targetDocument.documentType.parent.businessPartnerType,
            businessPartnerId: this.targetDocument.businessPartner.id,
            documentItems: [],
            workingDocumentItem: {},
            loading: false,
            currentOffset: 0,
            totalRecords: 0,
            rows: this.defaultRows,
            filters: {},
            params: new URLSearchParams(),
            gql: `
                id {
                    documentTypeId
                    number
                    prog
                }
                product {
                    id 
                    partnumber
                    name
                    category {
                        id
                        name
                    }
                }
                color {
                    id
                    name
                }
                size {
                    id
                    name
                }
                quantity
                quantityProcessed
                quantityToProcess
                netUnitPrice
                document {
                    id {
                        documentTypeId
                        number
                    }
                    date
                }`
        }
    },
    mounted() {
        this.filters['id.documentTypeId'] = this.documentTypeId;
        this.filters['document.businessPartnerType'] = this.businessPartnerType;
        this.filters['document.businessPartner.id'] = this.businessPartnerId;
        this.filters['document.status'] = 'N';
        this.filters['>quantityToProcess'] = '0';
        this.params = new URLSearchParams('id.documentTypeId=' + this.documentTypeId + 
                                            '&document.businessPartnerType='+this.businessPartnerType+
                                            '&document.businessPartner.id='+this.businessPartnerId+
                                            '&document.status=N'+
                                            '&>quantityToProcess=0');
        this.doSearch(0);
    },
    methods: {
        doSearch(offset) {
            this.loading = true;
            dataService.searchGraphQLAPI('searchDocumentItems', this.params, this.gql, offset, this.rows)
                .then(res => {
                    console.log(res);
                    this.totalRecords = res.rowCount;
                    this.documentItems = res.elements;
                    this.currentOffset = offset;
                    this.loading = false;
                });
        },
        formatDate,
        formatAmount,
        onPage(event) {
            this.params = parametersFromEvent(event);
            this.doSearch(event.first)
        },
        filter(event) {
          const dt = this.$refs.dataTable;
          console.log(dt.sortField);
          dt.$emit('filter', dt.createLazyLoadEvent(event));
          dt.resetPage();
        },
        onFilter(event) {
          this.params = parametersFromEvent(event);
          this.doSearch(0);
        },
        onSort(event) {
          this.params = parametersFromEvent(event);
          this.doSearch(0);
        },
        process(documentItem) {
            this.$root.loading = true;
            this.workingDocumentItem = documentItem;
            dataService.addDocumentItemFromRelated(this.targetDocument, documentItem, documentItem.q)
                .then(res => {
                    this.$root.loading = false;
                    console.log(res);
                    this.$toast.add({severity:'success', summary: 'Successo', detail: 'Riga ' + res.id.prog + ' aggiunta al documento', life: 5000});
                    this.$emit('added', res);
                    //documentItem.quantityProcessed += documentItem.q;
                    //documentItem.q = '';
                    
                    var par = new URLSearchParams();
                    par.set('id.documentTypeId', documentItem.id.documentTypeId);
                    par.set('id.number', documentItem.id.number);
                    par.set('id.prog', documentItem.id.prog);
                    dataService.searchGraphQLAPI('searchDocumentItems', par, this.gql, 0, 1)
                        .then(di => {
                            console.log(di);
                            this.workingDocumentItem.quantityProcessed = di.elements[0].quantityProcessed;
                            this.workingDocumentItem.quantityToProcess = di.elements[0].quantityToProcess;
                            this.workingDocumentItem.q = null;
                            //this.workingDocumentItem = di.elements[0];
                        });
                        
                }).catch(error => {
                    this.$root.loading = false;
                    //this.$toast.add({severity:'error', summary: 'Errore', detail: error, life: 5000});
                    this.$swal({
                        icon: 'error',
                        title: 'Errore',
                        text: error.response?.data.message,
                    });
                    console.log(error.response?.data.message);
                
                });
        }
    }
}
</script>