<template>
    <Card>
        <template #content>
            <form ref="filterForm" @keyup.enter="filter($event)">
                <div class="p-grid p-jc-center p-mt-2">
                    <div class="p-field p-col-12 p-md-3">
                        <Supplier v-model="filters['document.businessPartner.id']" optionValue="id" :showClear="true"  />
                    </div>
                    <div class="p-field p-col-12 p-md-1">
                        <span class="p-float-label">
                            <Date v-model="filters['>=document.date']" :manualInput="false" :showButtonBar="true" />
                            <label>Dalla data</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-1">
                        <span class="p-float-label">
                            <Date v-model="filters['<=document.date']" :manualInput="false" :showButtonBar="true" />
                            <label>Alla data</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <Season v-model="filters['product.season.id']" optionValue="id" :showClear="true" />
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <ProductType v-model="filters['product.productType.id']" optionValue="id" :showClear="true" />
                    </div>

                    <Button ref="filterButton" label="Elabora" @click="process()" class="p-button p-col-12" />
                    
                </div>
            </form>
        </template>
    </Card>

    <div class="p-grid p-jc-center p-mt-4">
        <div class="vld-parent p-col-3">
            <Loading :active="purchasedLoading" 
                    :can-cancel="false" 
                    background-color="#000"
                    :opacity="0.4"
                    :is-full-page="false"></Loading>
            
            <Card style="height: 400px">
                <template #header>Acquistato</template>
                <template #content>
                    <div class="p-grid">
                        <div class="p-col-12 p-text-center">
                            <h4>Numero pezzi</h4>
                            <h1>{{purchased.quantity}}</h1>
                        </div>
                        
                        <div class="p-col-12 p-text-center">
                            <h4>Valore imponibile</h4>
                            <h1>{{formatAmount(purchased.amount)}}</h1>
                        </div>
                    </div>       
                </template>
            </Card>

        </div>


        <div class="vld-parent p-col-3">
            <Loading :active="returnedLoading" 
                    :can-cancel="false" 
                    background-color="#000"
                    :opacity="0.4"
                    :is-full-page="false"></Loading>
            
            <Card style="height: 400px">
                <template #header>Reso</template>
                <template #content>
                    <div class="p-grid">
                        <div class="p-col-12 p-text-center">
                            <h4>Numero pezzi</h4>
                            <h1>{{returned.quantity}}</h1>
                        </div>
                        <div class="p-col-12 p-text-center">
                            <h4>Valore imponibile</h4>
                            <h1>{{formatAmount(returned.amount)}}</h1>
                        </div>
                    </div>       
                </template>
            </Card>

        </div>


        <div class="vld-parent p-col-3">
            <Loading :active="soldLoading" 
                    :can-cancel="false" 
                    background-color="#000"
                    :opacity="0.4"
                    :is-full-page="false"></Loading>
            
            <Card style="height: 400px">
                <template #header>Venduto</template>
                <template #content>
                    <div class="p-grid">
                        <div class="p-col-12 p-text-center">
                            <h4>Numero pezzi</h4>
                            <h1>{{sold.quantity}}</h1>
                        </div>
                        <div class="p-col-12 p-text-center">
                            <h4>Valore imponibile</h4>
                            <h1>{{formatAmount(sold.amount)}}</h1>
                        </div>
                    </div>       
                </template>
            </Card>

        </div>

        <div class="vld-parent p-col-3">
            <Loading :active="orderedLoading" 
                    :can-cancel="false" 
                    background-color="#000"
                    :opacity="0.4"
                    :is-full-page="false"></Loading>
            
            <Card style="height: 400px">
                <template #header>Ordinato</template>
                <template #content>
                    <div class="p-grid">
                        <div class="p-col-12 p-text-center">
                            <h4>Numero pezzi</h4>
                            <h1>{{ordered.quantity}}</h1>
                        </div>
                        <div class="p-col-12 p-text-center">
                            <h4>Valore imponibile</h4>
                            <h1>{{formatAmount(ordered.amount)}}</h1>
                        </div>
                    </div>       
                </template>
            </Card>

        </div>

    </div>
</template>
<script>
import {dataService} from '../services/DataService';
import {formatAmount} from '../Utils';
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
    components: {
        'Loading': Loading
    },
    data() {
        return {
            purchased: {},
            purchasedLoading: false,
            sold: {},
            soldLoading: false,
            returned: {},
            returnedLoading: false,
            ordered: {},
            orderedLoading: false,
            Loading: false,
            filters: {},
        }
    },
    methods: {
        formatAmount,
        process() {
            var params = new URLSearchParams();
            for (var f in this.filters) {
                //console.log(f);
                if (this.filters[f])
                    params.set(f, this.filters[f]);
            }
            this.purchasedLoading = true;
            dataService.get('/api/documentitems/stats/purchased?' + params.toString())
                .then(res => {
                    console.log(res);
                    this.purchased = res;
                    this.purchasedLoading = false;
                })
                .catch(error => {
                    this.purchasedLoading = false;
                    this.$swal({
                        icon: 'error',
                        title: 'Errore',
                        text: error.response?.data.message,
                    });
                });

            this.returnedLoading = true;
            dataService.get('/api/documentitems/stats/returned?' + params.toString())
                .then(res => {
                    console.log(res);
                    this.returned = res;
                    this.returnedLoading = false;
                })
                .catch(error => {
                    this.returnedLoading = false;
                    this.$swal({
                        icon: 'error',
                        title: 'Errore',
                        text: error.response?.data.message,
                    });
                })

            this.soldLoading = true;
            dataService.get('/api/documentitems/stats/sold?' + params.toString())
                .then(res => {
                    console.log(res);
                    this.sold = res;
                    this.soldLoading = false;
                })
                .catch(error => {
                    this.soldLoading = false;
                    this.$swal({
                        icon: 'error',
                        title: 'Errore',
                        text: error.response?.data.message,
                    });
                })
            
            this.orderedLoading = true;
            dataService.get('/api/documentitems/stats/ordered?' + params.toString())
                .then(res => {
                    console.log(res);
                    this.ordered = res;
                    this.orderedLoading = false;
                })
                .catch(error => {
                    this.orderedLoading = false;
                    this.$swal({
                        icon: 'error',
                        title: 'Errore',
                        text: error.response?.data.message,
                    });
                })    
        }
    }
}
</script>