/* eslint-disable no-unused-vars */
import {createApp} from 'vue';
import PrimeVue from 'primevue/config';
import router from './router';
import App from './App.vue';
import VueBarcodeScanner from './components/vue-barcode-scanner';
import VueSweetalert2 from 'vue-sweetalert2';
import { store } from '@/stores'

import Button from 'primevue/button';
import DataView from 'primevue/dataview';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputMask from 'primevue/inputmask';
import Password from 'primevue/password';
import AutoComplete from 'primevue/autocomplete';
import Menubar from 'primevue/menubar';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Dropdown from 'primevue/dropdown';
import Toolbar from 'primevue/toolbar';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import ProgressSpinner from 'primevue/progressspinner';
import Panel from 'primevue/panel';
import Badge from 'primevue/badge';
import Tooltip from 'primevue/tooltip';

import Warehouse from './components/input/Warehouse';
import Season from './components/input/Season';
import Customer from './components/input/Customer';
import Supplier from './components/input/Supplier';
import Country from './components/input/Country';
import Category from './components/input/Category';
import Brand from './components/input/Brand';
import SizeScale from './components/input/SizeScale';
import Vat from './components/input/Vat';
import ProductType from './components/input/ProductType';
import UnitOfMeasure from './components/input/UnitOfMeasure';
import Color from './components/input/Color';
import Date from './components/input/Date';


import 'primevue/resources/themes/saga-orange/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/style.css';

const app = createApp(App);

app.use(PrimeVue, {
  locale: {
    firstDayOfWeek: 1,
    dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    dayNamesMin: ["Do","Lu","Ma","Me","Gi","Ve","Sa"],
    monthNames: [ "Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre" ],
    monthNamesShort: [ "Gen", "Feb", "Mar", "Apr", "Mag", "Giu","Lug", "Ago", "Set", "Ott", "Nov", "Dic" ],
    today: 'Oggi',
    clear: 'Pulisci',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Set'
  }
});
app.use(ToastService);
app.use(router);
app.use(VueBarcodeScanner);
app.use(VueSweetalert2);
app.use(store);

app.component('Button', Button);
app.component('DataView', DataView);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Card', Card);
app.component('Panel', Panel);
app.component('Checkbox', Checkbox);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('InputMask', InputMask);
app.component('Password', Password);
app.component('AutoComplete', AutoComplete);
app.component('Menubar', Menubar);
app.component('Toast', Toast);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Dropdown', Dropdown);
app.component('Toolbar', Toolbar);
app.component('Dialog', Dialog);
app.component('Calendar', Calendar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Badge', Badge);

app.component('Warehouse', Warehouse);
app.component('Season', Season);
app.component('Customer', Customer);
app.component('Supplier', Supplier);
app.component('Country', Country);
app.component('Category', Category);
app.component('Brand', Brand);
app.component('SizeScale', SizeScale);
app.component('Vat', Vat);
app.component('ProductType', ProductType);
app.component('UnitOfMeasure', UnitOfMeasure);
app.component('Color', Color);
app.component('Date', Date);

app.config.globalProperties.defaultRows = 25;
app.config.productionTip = false;

app.directive('tooltip', Tooltip);

app.directive('focus', {
  beforeMount(el, binding, vnode) {
    console.log('mount directive');
    el.focus();
  }
})

app.config.globalProperties.$loading = false

app.mount('#app');

export default app;
