<template>
    <div id="item-list">
        <DataTable ref="dataTable" :value="itemStocks" :lazy="true" :paginator="true" :rows="rows" :autoLayout="true"
            :totalRecords="totalRecords" :filters="filters" :loading="loading" @page="onPage($event)" @sort="onSort($event)"  @filter="onFilter($event)">


            <Column field="warehouse.id" header="Magazzino" :sortable="true" />
                
            <Column field="color.id" header="Colore" :sortable="true">
                <template #body="slotProps">
                    {{slotProps.data.color.id}} - {{slotProps.data.color.name}}
                </template>
            </Column>
            <Column field="size.id" header="Taglia" :sortable="true">
                <template #body="slotProps">
                    {{slotProps.data.size.id}} - {{slotProps.data.size.name}}
                </template>
            </Column>
            <Column field="ean" header="Barcode" :sortable="true" />

            <Column field="barcodes" header="Barcode aggiuntivi">
                <template #body="slotProps">
                    <ul class="barcode-list">
                        <li v-for="item in slotProps.data.barcodes" :key="item.id">
                            {{ item.id }}
                        </li>
                    </ul>
                </template>
            </Column>

            <Column bodyClass="p-text-right">
                <template #body="slotProps">
                    <Button icon="fas fa-barcode" class="p-button-rounded p-button-success p-mr-2" title="Associa barcode" @click="addBarcode(slotProps.data)" />
                    <Button icon="fas fa-tag" class="p-button-rounded p-button-warning p-mr-2" title="Stampa etichette" @click="printLabel(slotProps.data)" v-if="slotProps.data.ean" />
                </template>
            </Column>
        </DataTable>
    </div>

    <ItemBarcode :item="item" v-model:show="itemBarcodeDialog" @success="onItemBarcodeSuccess($event)"  @cancel="onItemBarcodeCancel()" />

    <ItemLabel :item="item" v-model:show="itemLabelDialog" />

</template>
<script>
import {dataService} from '../services/DataService';
import {parametersFromEvent} from '../Utils';
import ItemBarcode from './ItemBarcode'
import ItemLabel from './ItemLabel'

export default {
    name: 'ItemStockList',
    components: {
        'ItemBarcode' : ItemBarcode,
        'ItemLabel' : ItemLabel
    },
    props: {
        productId: String
    },
    data() {
        return {
            itemStocks: [],
            loading: false,
            totalRecords: 0,
            seasons: null,
            currentOffset: 0,
            rows: this.defaultRows,
            filters: {},
            params: new URLSearchParams(),
            item: {},
            itemBarcodeDialog: false,
            itemLabelDialog: false,
            sortField: String,
            sortOrder: Number,
            itemGQL: 
                `id {
                    productId
                    warehouseId
                    colorId
                    sizeId
                }
                product {
                    id
                    partnumber
                    category {
                        id
                        name
                    }
                }
                warehouse {
                    id
                    name
                }
                color {
                    id
                    name
                }
                size {
                    id
                    name
                }
                ean
                barcodes {
                    id
                }`
        }
    },
    created() {
    },
    mounted() {
        if (this.productId != undefined) {
            this.params.set('product.id', this.productId);
            this.filters['product.id'] = this.productId;
            this.loading = true;
            this.doSearch(this.currentOffset);
        }
    },
    methods: {
        doSearch(offset) {
            this.loading = true;
            dataService.searchGraphQLAPI('searchItems', this.params, this.itemGQL, offset, this.rows)
            .then(res => {
                this.itemStocks = res.elements;
                this.totalRecords = res.rowCount;
                this.loading = false;
                this.currentOffset = offset;
            })
            .catch(error => {
                console.log(error);
                this.loading = false;
                this.$swal({
                    icon: 'error',
                    title:  'Errore',
                    text: error.response?.data.message,
                });               
            });
        },
        onPage(event) {
            this.loading = true;
            this.params = parametersFromEvent(event);
            this.doSearch(event.first)
        },
        filter(event) {
          const dt = this.$refs.dataTable;
          console.log(dt.sortField);
          dt.$emit('filter', dt.createLazyLoadEvent(event));
          dt.resetPage();
        },
        onFilter(event) {
          console.log(event);
          this.loading = true;
          this.params = parametersFromEvent(event);
          this.doSearch(0);
        },
        onSort(event) {
          console.log(event);
          this.loading = true;
          this.params = parametersFromEvent(event);
          this.doSearch(0);
        },
        addBarcode(item) {
            this.item = item;
            this.itemBarcodeDialog = true;
        },
        printLabel(item) {
            this.item = item;
            this.itemLabelDialog = true;
        },
         onItemBarcodeCancel() {
            console.log('CANCELLED!')
        },
         onItemBarcodeSuccess(event) {
            console.log('SUCCESS!');
            console.log(event);
            this.doSearch(this.currentOffset);
        },
    }
}
</script>