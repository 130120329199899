<template>
    <div id="document-item-list">

        <Toolbar class="p-mb-4">
            <template v-slot:left>
                <Button label="Nuova riga" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew"/>
            </template>
            <template v-slot:right>
                <div class="p-grid">
                    
                    <div class="p-col" style="white-space: nowrap;">
                        <div>Capi: <b>{{totalQuantity}}</b></div>
                    </div>
                    
                     <div class="p-col"  style="white-space: nowrap;">
                        <div>Imponibile: <b>{{formatAmount(totalAmount)}}</b></div>
                    </div>

                </div>
                <!--
                <Button label="Da evadere" icon="pi pi-external-link" class="p-button-warning p-mr-2" @click="openProcessing" v-if="processable"/>
                -->
            </template>
        </Toolbar>
        
        <OrderProductsDT v-if="document.documentType.stockReason.id == 'F01'" :value="documentProducts" :rows="rows" :totalRecords="totalRecords" :loading="loading" :filters="filters"
                    @page="onPage($event)" @sort="onSort($event)"  @filter="onFilter($event)" 
                    @edit="editDocumentProduct($event)"  />
        
        <DocumentProductsDT v-else :value="documentProducts" :rows="rows" :totalRecords="totalRecords" :loading="loading" :filters="filters"
                    @page="onPage($event)" @sort="onSort($event)"  @filter="onFilter($event)" 
                    @edit="editDocumentProduct($event)" @delete="confirmDeleteDocumentItems($event)" @processload="processLoad($event)" />

    </div>

    <Dialog position="top" v-model:visible="documentProductDialog" :style="{width: '850px'}" header="Riga documento" :modal="true" class="p-fluid">
        <form v-on:keyup.enter="saveDocumentProduct">
        <div class="p-formgrid p-grid" >

            <div class="p-field p-col-12" v-if="itemBarcode">
                <label for="barcode">Barcode</label>
                <InputText id="barcode" v-model="itemBarcode" />
            </div>

            <div class="p-field p-col-12" v-if="isNewDocumentProduct">
                
                <TabView>
                    <TabPanel header="Codice">
                        <div class="p-formgrid p-grid" >
                            <div class="p-col-11">
                                <AutoComplete id="product" v-model="documentProduct.product" :suggestions="productList" :minLength=2
                                    @complete="searchProductsByPartnumber($event)" @item-select="onProductSelect($event)" field="name" :dropdown="true" required>
                                    <template #item="slotProps">
                                        <div v-if="slotProps.item">
                                        {{slotProps.item.category.id}} - {{slotProps.item.partnumber}} - {{slotProps.item.name}}
                                        </div>
                                    </template>
                                </AutoComplete>
                            </div>
                            <div class="p-col-1">
                                <Button icon="fas fa-plus" title="Nuovo prodotto" @click="newProduct()" />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Descrizione">
                        <div class="p-formgrid p-grid" >
                            <div class="p-col-11">
                                <AutoComplete id="product" v-model="documentProduct.product" :suggestions="productList" :minLength=2
                                    @complete="searchProductsByName($event)" @item-select="onProductSelect($event)" field="name" :dropdown="true" required>
                                    <template #item="slotProps">
                                        <div v-if="slotProps.item">
                                        {{slotProps.item.category.id}} - {{slotProps.item.partnumber}} - {{slotProps.item.name}}
                                        </div>
                                    </template>
                                </AutoComplete>
                            </div>
                            <div class="p-col-1">
                                <Button icon="fas fa-plus" title="Nuovo prodotto" @click="newProduct()" />
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
                <small class="p-invalid" v-if="submitted && !documentProduct.product">Campo obbligatorio</small>
            </div>
            
            <div class="p-field p-col-12" v-if="documentProduct.product?.id">
                <label for="categoryDesc">Categoria: </label>
                <div id="categoryDesc"><b>{{documentProduct.product.category.id}} - {{documentProduct.product.category.name}}</b></div>
            </div>
            <div class="p-field p-col-12" v-if="documentProduct.product?.id">
                <label for="productDesc">Articolo: </label>
                <div id="productDesc"><b>{{documentProduct.product.partnumber}} - {{documentProduct.product.name}}</b></div>
            </div>
            
            <div class="p-field p-col">
                <label for="warehouse">Magazzino <i class="fas fa-redo" @click="loadWarehouses()" style="cursor: pointer" v-if="isNewDocumentProduct" @change="onWarehouseChange"></i></label>
                <Dropdown id="warehouse" v-model="documentProduct.warehouse" :options="warehouseList" optionLabel="id" :disabled="!isNewDocumentProduct" />
                <small class="p-invalid" v-if="submitted && !documentProduct.warehouse">Campo obbligatorio</small>
            </div>

            <div class="p-field p-col">
                <label for="color">Colore <i class="fas fa-redo" @click="loadColors()" style="cursor: pointer" v-if="isNewDocumentProduct"></i></label>
                <Dropdown id="color" v-model="documentProduct.color" :options="colorList" :filter="colorList?.length > 5" optionLabel="id" :disabled="!isNewDocumentProduct" @change="onColorChange">
                     <template #option="slotProps">
                        {{slotProps.option.id}} -  {{slotProps.option.name}}
                     </template>
                </Dropdown>
                <small class="p-invalid" v-if="submitted && !documentProduct.color">Campo obbligatorio</small>
            </div>

            <div class="p-field p-col">
                <label for="vat">IVA</label>
                <Dropdown id="vat" v-model="documentProduct.vat" :options="vatList" optionLabel="name">
                     <template #option="slotProps">
                        {{slotProps.option.id}} -  {{slotProps.option.name}}
                     </template>
                </Dropdown>
                <small class="p-invalid" v-if="submitted && !documentProduct.vat">Campo obbligatorio</small>
            </div>
            
            <div class="p-field p-col">
                <label for="disc">Sc%</label>
                <InputNumber id="disc" v-model="documentProduct.discountPercentage" :disabled="documentProduct.parent" />
            </div>

            <div class="p-field p-col">
                <label for="price">Prezzo netto</label>
                <InputNumber id="price" v-model="documentProduct.netUnitPrice" mode="currency" currency="EUR" locale="it-IT" />
                <small class="p-invalid" v-if="submitted && !documentProduct.netUnitPrice">Campo obbligatorio</small>
            </div>

            <div class="p-field p-col-12">
                <label for="qtys">Quantità per taglia</label>
                <div id="qtys" class="p-grid" style="max-height: 120px; overflow-y: auto">
                    <div class="p-col no-padding" v-for="item in documentProduct.items" :key="item.size.id">
                        <div class="main-col tg-col">{{item.size.id}} <Badge :value="item.quantityToProcess" v-if="item.quantityToProcess" v-tooltip="'Ci sono ' +item.quantityToProcess+' capi in arrivo per questa taglia'" /></div> 
                        <div class="sec-col qty-col" style="height: 60px"><InputNumber v-model="item.quantity" :disabled="item.status == 'X'" class="p-inputtext-sm centered-bold" /></div>    
                    </div> 
                </div>
            </div>

            <!--
            <div class="p-field p-col-4">
                <label for="size">Taglia <i class="fas fa-redo" @click="loadSizesBySizeScale(documentProduct.product.sizeScale?.id)" style="cursor: pointer"></i></label>
                <Dropdown id="size" v-model="documentProduct.size" :options="sizeList" optionLabel="id" />
                <small class="p-invalid" v-if="submitted && !documentProduct.size">Campo obbligatorio</small>
            </div>
            -->
        </div>
        
        </form>

        <template #footer>
            <Button label="Annulla" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button ref="saveItemButton" label="Salva" icon="pi pi-check" class="p-button-text" @click="confirmSaveDocumentProduct" />
        </template>
        
    </Dialog>

    <Dialog v-model:visible="confirmDialog" :style="{width: '450px'}" header="Conferma richiesta" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            <span v-html="confirmMessage"></span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="confirmDialog = false"/>
            <Button label="Sì" icon="pi pi-check" class="p-button-text" @click="confirmNextSaveDocumentProduct" />
        </template>
    </Dialog>

    <Dialog v-model:visible="confirmDeleteDialog" :style="{width: '450px'}" header="Conferma cancellazione" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            <span v-html="confirmDeleteMessage"></span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="confirmDeleteDialog = false"/>
            <Button label="Sì" icon="pi pi-check" class="p-button-text" @click="deleteDocumentItems(itemsToDelete)" />
        </template>
    </Dialog>

    <Dialog v-model:visible="processingItemDialog" style="width: 90vw" :contentStyle="{height: 'calc(100vh - 200px)'}" header="Evasione merce" :modal="true" class="p-fluid">
        <ProcessingDocumentItemList v-if="document" :targetDocument="document"  :componentKey="componentKey" @added="onItemAddedFromRelated()" />
        <template #footer>
        </template>
    </Dialog>

    <Dialog v-model:visible="productDialog" :style="{width: '1000px'}" header="Nuovo prodotto" :modal="true" @show="$refs.productDetail.createProduct()">
        <ProductDetail ref="productDetail" @saved="onNewProduct($event)" />
        <template #footer>
            <Button label="Annulla" icon="pi pi-times" class="p-button-text" @click="deletedocumentProductDialog = false"/>
            <Button label="Salva" icon="pi pi-check" class="p-button-text" @click="$refs.productDetail.saveProduct()" />
        </template>
    </Dialog>

</template>
<script>
import {dataService} from '../services/DataService';
import {parametersFromEvent, formatDate, formatAmount} from '../Utils';
import ProcessingDocumentItemList from './ProcessingDocumentItemList';
import ProductDetail from './ProductDetail';
import OrderProductsDT from './OrderProductsDT';
import DocumentProductsDT from './DocumentProductsDT';
import {store} from "@/stores";

export default {
    name: 'DocumentProductList',
    props: {
        document: Object
    },
    components: {
        'ProcessingDocumentItemList': ProcessingDocumentItemList,
        'ProductDetail': ProductDetail,
        'OrderProductsDT': OrderProductsDT,
        'DocumentProductsDT': DocumentProductsDT
    },
    data() {
        return {
            documentTypeId: String,
            documentNumber: String,
            documentType: null,
            loading: false,
            totalRecords: 0,
            documentProducts : null,
            isNewDocumentProduct: false,
            documentProduct : {}, //this.createdocumentProduct(),
            documentProductDialog: false,
            processingItemDialog: false,
            productDialog: false,
            rows: this.defaultRows,
            filters: {},
            sortField: String,
            sortOrder: Number,
            block: false,
            params: URLSearchParams,
            productList: [],
            warehouseList: [],
            vatList: [],
            colorList: [],
            sizeList: [],
            submitted: false,
            componentKey: 0,
            processable: false,
            currentOffset: 0,
            itemBarcode: null,
            totalQuantity: 0,
            totalAmount: 0,
            confirmationCount: 0,
            confirmMessage: '',
            confirmDialog: false,
            confirmDeleteDialog: false,
            confirmDeleteMessage: '',
            itemsToDelete: [],
            fields: `
                serializedId
                product {
                    id
                    partnumber
                    name
                    brand {id name}
                    category {id name}
                    vat {id name}
                    season {id name}
                    price
                    purchasePrice
                }
                warehouse {id name}
                color {id name }
                vat {id name percentage} 
                quantity 
                quantityProcessed
                quantityToProcess
                discountPercentage
                unitPrice 
                netUnitPrice 
                netTotal 
                taxTotal 
                total
                status
                items {
                    id {prog}
                    size {id name}
                    quantity 
                    quantityProcessed
                    status
                }
            `
        }
    },
    created() {
    },
    mounted() {
        console.log('documentProductList --> mounted');
        this.documentTypeId = this.document.id.documentTypeId;
        this.documentNumber = this.document.id.number;
        this.params = new URLSearchParams();
        if (this.documentTypeId != undefined) {
            this.filters['id.documentTypeId'] = this.documentTypeId;
            this.filters['id.number'] = this.documentNumber;
            this.params.set('id.documentTypeId', this.documentTypeId);
            this.params.set('id.number', this.documentNumber);
            this.params.set('sortProperty', 'lastUpdate');
            this.params.set('sortOrder', 'desc');
            console.log(this.documentTypeId + ' - ' + this.documentNumber);
            this.loading = true;

            dataService.getDocumentType(this.documentTypeId).then(res => {
                this.documentType = res;
                if (this.documentType.parent != null)
                    this.processable = true;
            });

            this.doSearch(0);
            this.loadVats();
        }
    },
    //params : new URLSearchParams()
    activated() {     
        console.log('documentProductList --> activated');
    },
    methods: {
        formatDate,
        formatAmount,
        doSearch(offset) { 
            this.loading = true;
            dataService.searchGraphQLAPI('searchDocumentProducts', this.params, this.fields, offset, this.rows).then(data => {
                this.totalRecords = data.rowCount,
                this.documentProducts = data.elements
                this.loading = false;
                this.currentOffset = offset;
            }); 
            this.computeTotalAmount();
            this.computeTotalQuantity();
        },
        onPage(event) {
            console.log(event);
            this.params = parametersFromEvent(event);
            this.doSearch(event.first);
        },
        onItemAddedFromRelated() {
            this.doSearch(0);
        },
        onFilter(event) {
          console.log(event);
          this.params = parametersFromEvent(event);
          this.doSearch(0);
        },
        onSort(event) {
          console.log(event);
          this.params = parametersFromEvent(event);
          this.doSearch(0); 
        },
        loadVats() {
            if (this.vatList.length == 0)
                dataService.listVats().then(data => {this.vatList = data.elements})
        },
        openNew() {
            this.createDocumentProduct();
        },
        hideDialog() {
            this.documentProductDialog = false;
        },
        createDocumentProduct() {
            this.documentProduct = {product: null, items: [], warehouse: this.document.warehouse};
            this.submitted = false;
            this.isNewDocumentProduct = true;
            this.documentProductDialog = true;
            /*
            this.$root.loading = true;
            return dataService.newdocumentProduct(this.documentTypeId, this.document.id.number).then(res => {
                res.quantity = 1;
                res.warehouse = this.document.warehouse;
                this.documentProduct = res;
                this.$root.loading = false;
                this.submitted = false;
                this.colorList = [];
                this.sizeList = [];
                this.productList = [];
                this.documentProductDialog = true;
            })
            */
        },
        searchProducts(event) {
            dataService.searchProductsByTerm(event.query).then(data => this.productList = data);
        },
        searchProductsByPartnumber(event) {
            dataService.searchProducts(new URLSearchParams('partnumber=' + event.query + '%&sortProperty=partnumber&sortOrder=asc'), 0, 15).then(data => this.productList = data.elements);
        },
        searchProductsByName(event) {
            dataService.searchProducts(new URLSearchParams('name=%' + event.query + '%&sortProperty=name&sortOrder=asc'), 0, 15).then(data => this.productList = data.elements);
        },
        onProductSelect(event) {
            console.log(event);
            this.documentProduct.product = event.value;
            //this.documentProduct.warehouse = null;
            this.documentProduct.vat = event.value.vat;
            this.documentProduct.color = null;
            if (this.document.documentType.protocolType == 'V') {
                if (event.value.price) {
                    var amount = event.value.price;
                    amount *= ((100-this.documentProduct.vat.percentage)/100);
                    console.log(amount);
                    this.documentProduct.netUnitPrice = amount;
                    this.documentProduct.unitPrice = amount;
                }
            }
            if (this.document.documentType.protocolType == 'A' || this.document.documentType.protocolType == 'M') {
                if (event.value.purchasePrice) {
                    this.documentProduct.netUnitPrice = event.value.purchasePrice;
                    this.documentProduct.unitPrice = event.value.purchasePrice;
                }
            }
            dataService.listProductWarehouses(event.value).then(data => {
                this.warehouseList = data;
                if (this.warehouseList.length == 1) {
                    this.documentProduct.warehouse = this.warehouseList[0];
                    this.loadProcessingItems();
                } else {
                     this.documentProduct.warehouse = this.document.warehouse;
                     if (this.warehouseList.length == 0)
                        this.loadWarehouses();
                }
            });
            dataService.listProductColors(event.value).then(data => {
                this.colorList = data;
                if (this.colorList.length == 1) {
                    this.documentProduct.color = this.colorList[0];
                    this.loadProcessingItems();
                } else {
                    if (this.colorList.length == 0)
                        this.loadColors();
                }
            });
            dataService.listSizesBySizeScale(event.value.sizeScale?.id).then(data => {
                this.documentProduct.items = [];
                for (var i = 0; i < data.elements.length; i++) {
                    this.documentProduct.items[i] = {size: data.elements[i]};
                }
            });
            
        },
        onWarehouseChange(event) {
            console.log(event);
            //this.$toast.add({severity:'success', summary: 'Warehouse changed', detail: event.value, life: 5000});
            this.loadProcessingItems();
        },
        onColorChange(event) {
            console.log(event);
            //this.$toast.add({severity:'success', summary: 'Color changed', detail: event.value, life: 5000});
            this.loadProcessingItems();
        },
        getItemBySizeId(sizeId) {
            for (var i = 0; i < this.documentProduct.items?.length; i++) {
                if (this.documentProduct.items[i].size.id == sizeId)
                    return this.documentProduct.items[i];
            }
            return null;
        },
        editDocumentProduct(p) {
            this.submitted = false;
            this.isNewDocumentProduct = false;
            this.$root.loading = true;
            Object.assign(this.documentProduct, p);
            var parameters = new URLSearchParams('products.id=' + p.product.id);
            dataService.searchGraphQLAPI('searchSizeScales', parameters, "id name entries {id {size {id name}}}", 0, 1)
                .then(res => {
                    console.log(res);
                    dataService.listProductWarehouses(this.documentProduct.product).then(data => {
                        this.warehouseList = data;
                        if (this.warehouseList.length == 0)
                            this.loadWarehouses();
                    });
                    dataService.listProductColors(this.documentProduct.product).then(data => {
                        this.colorList = data;
                        if (this.colorList.length == 0)
                            this.loadColors();
                    });
                    var sizeScale = res.elements[0];
                    var arr = [];
                    for (var i = 0; i < sizeScale.entries.length; i++) {
                        //console.log(sizeScale.entries[i].id.size.id);
                        var ent = this.getItemBySizeId(sizeScale.entries[i].id.size.id);
                        arr[i] = {
                            id: {
                                prog: ent?.id.prog
                            },
                            size : sizeScale.entries[i].id.size,
                            quantity: ent?.quantity,
                            status: ent?.status
                        }
                    }
                    this.documentProduct.items = arr;
                    this.productList = [];
                    this.$root.loading = false;
                    this.documentProductDialog = true;

                    this.loadProcessingItems();

                })
                .catch(error => {
                        this.$root.loading = false;
                        //this.$toast.add({severity:'error', summary: 'Errore', detail: error, life: 5000});
                        this.$swal({
                            icon: 'error',
                            title: 'Errore',
                            text: error.response?.data.message,
                        });
                    });
            
        },
        loadProcessingItems() {
            if (this.documentProduct?.warehouse && this.documentProduct?.color) {
                if (this.document.documentType.stockReason.id == 'CAR' && this.document.documentType.parent) {
                    var p = new URLSearchParams();
                    p.set('id.documentTypeId', this.document.documentType.parent.id);
                    p.set('id.productId', this.documentProduct.product.id);
                    p.set('id.warehouseId', this.documentProduct.warehouse.id);
                    p.set('id.colorId', this.documentProduct.color.id);
                    dataService.searchGraphQLAPI('searchProcessingItems', p, 'id {sizeId} quantityToProcess', 0, 0)
                        .then(res => {
                            console.log(res);
                            if (res.elements) {
                                for (var i = 0; i < res.elements.length; i++) {
                                    var o = res.elements[i];
                                    var item = this.getItemBySizeId(o.id.sizeId);
                                    if (item)
                                        item.quantityToProcess = o.quantityToProcess;
                                }
                            }
                        });
                }
            }
        },
        isValidDocumentProduct() {
            return this.documentProduct.product
                    && this.documentProduct.warehouse
                    && this.documentProduct.color
                    && this.documentProduct.netUnitPrice
                    && this.documentProduct.vat;
        },
        confirmSaveDocumentProduct() {
            this.submitted = true;
            if (this.isValidDocumentProduct()) {
                if (this.isNewDocumentProduct) {
                    this.confirmationCount = 3;
                    this.confirmMessage = 'L\'articolo fa riferimento al magazzino <b>' + this.documentProduct.warehouse.id + '</b>\nContinuare?';
                    this.confirmDialog = true;
                } else {
                     this.saveDocumentProduct();
                }
            } else {
                this.$toast.add({severity:'warning', summary: 'Attenzione', detail: 'Compilare tutti i campi obbligatori!', life: 5000});
            }
        },
        confirmNextSaveDocumentProduct() {
            this.confirmDialog = false;
            this.confirmationCount++;
            if (this.confirmationCount == 1) {
                this.confirmMessage = 'Sei sicura che il magazzino giusto sia <b>' + this.documentProduct.warehouse.id + '</b>?';
                this.confirmDialog = true;
            } else if (this.confirmationCount == 2) {
                this.confirmMessage = 'Me sei SICURA SICURA che il magazzino giusto sia davvero <b>' + this.documentProduct.warehouse.id + '</b>?';
                this.confirmDialog = true;
            } else if (this.confirmationCount == 3) {
                this.confirmMessage = 'Va bene, mi hai convinto che il magazzino giusto sia <b>' + this.documentProduct.warehouse.id + '</b>... Poi non rompere le palle al programmatore bravo eh?!';
                this.confirmDialog  = true;
            } else {
                this.saveDocumentProduct();
            }

        },
        saveDocumentProduct() {
            this.submitted = true;
            if (this.isValidDocumentProduct()) {
                store.commit("loading/setLoading", true);
                this.documentProduct['id'] = {documentTypeId: this.document.id.documentTypeId, number: this.document.id.number};
                //this.documentProduct.product = {id: this.documentProduct.product.id};
                //this.documentProduct.serializedId = null;
                console.log(this.documentProduct);
                
                dataService.storeDocumentProduct(this.documentProduct)
                    .then(res => {
                        store.commit("loading/setLoading", false);
                                                
                        this.documentProductDialog = false;
                        //if ('DUPLICATED' != res.status) {
                            this.documentProduct = res;
                            this.$toast.add({severity:'success', summary: 'OK', detail: 'Riga documento salvata correttamente', life: 5000});
                            this.doSearch(this.currentOffset);
                        /*} else {
                            console.log('--> DUPLICATED!');
                            this.duplicatedItem = res;
                            this.duplicatedDialog = true;
                            this.duplicatedMessage = 'L\'articolo è già presente nel documento in quantità ' + res.quantity + '.\nCome vuoi procedere?';
                        }*/
                    })
                    .catch(error => {
                        store.commit("loading/setLoading", false);
                        //this.$toast.add({severity:'error', summary: 'Errore', detail: error, life: 5000});
                        this.$swal({
                            icon: 'error',
                            title: 'Errore',
                            text: error.response?.data.message,
                        });
                    });

            } else {
                this.$toast.add({severity:'warning', summary: 'Attenzione', detail: 'Compilare tutti i campi obbligatori!', life: 5000});
            }
        },
        confirmDeleteDocumentItems(arr) {
            this.confirmDeleteMessage = 'Il dato verrà cancellato. Proseguire?';
            this.confirmDeleteDialog = true;
            this.itemsToDelete = arr;
        },
        async deleteDocumentItems(arr) {
            console.log('DocumentItems', arr);
            this.confirmDeleteDialog = false;
            store.commit("loading/setLoading", true);
           
            try {
                for (const i of arr) {
                    await dataService.deleteDocumentItem(this.document.id.documentTypeId, this.document.id.number, i.id.prog);
                }
                this.doSearch(this.currentOffset);
                this.$toast.add({severity:'success', summary: 'OK', detail: 'Sono state cancellate ' + arr.length + ' righe dal documento', life: 5000});
                console.log('... end try');
            } catch (error) {
                this.$swal({
                            icon: 'error',
                            title: 'Errore',
                            text: error.response?.data.message,
                        });
            } finally {
                console.log('...finally')
                store.commit("loading/setLoading", false);
            }
        
        },
        newProduct() {
            this.productDialog = true;
        },
        onNewProduct(product) {
            //console.log('<><><><><><><>');
            console.log(product);
            this.productDialog = false;
            var event = {value : product, loadAll: true};
            /*if (!product.sizeScale) {
                dataService.searchProducts().then(res => {
                    product = res.elements[0]
                    event.value = product;
                    this.onProductSelect(event);
                });
            } else { */   
                this.onProductSelect(event);
            //}
        },
        loadWarehouses() {
            dataService.listWarehouses().then(res => {this.warehouseList = res.elements})
        },
        loadColors() {
            dataService.listColors().then(res => {this.colorList = res.elements})
        },
        loadSizesBySizeScale(sizeScaleId) {
           dataService.listSizesBySizeScale(sizeScaleId).then(res => {this.sizeList = res.elements});
        },
        openProcessing() {},
        computeTotalAmount() {
            dataService.getDocumentTotal(this.document.id.documentTypeId, this.document.id.number, this.params)
                .then(res => this.totalAmount = res);
        },
        computeTotalQuantity() {
            dataService.getDocumentTotalQuantity(this.document.id.documentTypeId, this.document.id.number, this.params)
                .then(res => this.totalQuantity = res);
        },
        processLoad(prog) {
            dataService.post('/api/documentitems/processload', {id: {documentTypeId: this.document.id.documentTypeId, number: this.document.id.number, prog: prog}})
                .then(res => {
                    this.$toast.add({severity:'success', summary: 'Processed', detail: 'Agganciato ordine ' + res.parent?.id.number, life: 5000});
                })
                .catch(error => {
                    this.$root.loading = false;
                    this.confirmConsolidateDialog = false;
                    //this.$toast.add({severity:'error', summary: 'Errore', detail: error, life: 5000});
                    this.$swal({
                        icon: 'error',
                        title: 'Errore',
                        text: error.response?.data.message,
                    });
                    console.log(error.response?.data.message);
                
                });
        }
    }
}
</script>