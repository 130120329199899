<template>
    <div id="item-stock-list">
        <DataTable ref="dataTable" :value="itemStocks" :lazy="true" :paginator="true" :rows="rows" :autoLayout="true"
            :totalRecords="totalRecords" :filters="filters" :loading="loading" @page="onPage($event)" @sort="onSort($event)"  @filter="onFilter($event)">


            <Column field="item.warehouse.id" header="Magazzino" :sortable="true" />
                
            <Column field="item.color.id" header="Colore" :sortable="true">
                <template #body="slotProps">
                    {{slotProps.data.item.color.id}} - {{slotProps.data.item.color.name}}
                </template>
            </Column>
            <Column field="item.size.id" header="Taglia" :sortable="true">
                <template #body="slotProps">
                    {{slotProps.data.item.size.id}} - {{slotProps.data.item.size.name}}
                </template>
            </Column>
            <Column field="item.ean" header="Barcode" :sortable="true" />

            <Column field="qtyLoaded" header="Caricato" :sortable="true" headerClass="p-text-right" bodyClass="p-text-right">
                <template #body="slotProps">
                    {{slotProps.data.qtyLoaded ? slotProps.data.qtyLoaded : 0}}
                </template>
                <template #loading>
                    <span class="loading-text"></span>
                </template>
            </Column>
            <Column field="qtyUnloaded" header="Scaricato" :sortable="true" headerClass="p-text-right" bodyClass="p-text-right">
                <template #body="slotProps">
                    {{slotProps.data.qtyUnloaded ? slotProps.data.qtyUnloaded : 0}}
                </template>
                <template #loading>
                    <span class="loading-text"></span>
                </template>
            </Column>
            <Column field="qtyExisting" header="Giacenza" :sortable="true" headerClass="p-text-right" bodyClass="p-text-right">
                <template #body="slotProps">
                   <b>{{slotProps.data.qtyExisting ? slotProps.data.qtyExisting : 0}}</b>
                </template>
                <template #loading>
                    <span class="loading-text"></span>
                </template>
            </Column>
            <!--
            <Column field="qtyAvailable" header="Disponibilità" :sortable="true" headerClass="p-text-right" bodyClass="p-text-right">
                <template #body="slotProps">
                    {{slotProps.data.qtyAvailable ? slotProps.data.qtyAvailable : 0}}
                </template>
                <template #loading>
                    <span class="loading-text"></span>
                </template>
            </Column>
            -->
            <Column field="qtyOrdered" header="In arrivo" :sortable="true" headerClass="p-text-right" bodyClass="p-text-right">
                <template #body="slotProps">
                    {{slotProps.data.qtyOrdered ? slotProps.data.qtyOrdered : 0}}
                </template>
                <template #loading>
                    <span class="loading-text"></span>
                </template>
            </Column>
            <Column bodyClass="p-text-right">
                <template #body="slotProps">
                    <Button icon="fas fa-barcode" class="p-button-rounded p-button-success p-mr-2" title="Associa barcode" @click="addBarcode(slotProps.data.item)" />
                    <Button icon="fas fa-tag" class="p-button-rounded p-button-warning p-mr-2" title="Stampa etichette" @click="printLabel(slotProps.data.item)" v-if="slotProps.data.item.ean" />
                </template>
            </Column>
        </DataTable>
    </div>

    <ItemBarcode :item="item" v-model:show="itemBarcodeDialog" @success="onItemBarcodeSuccess($event)"  @cancel="onItemBarcodeCancel()" />

    <ItemLabel :item="item" v-model:show="itemLabelDialog" />

</template>
<script>
import {dataService} from '../services/DataService';
import {parametersFromEvent} from '../Utils';
import ItemBarcode from './ItemBarcode'
import ItemLabel from './ItemLabel'

export default {
    name: 'ItemStockList',
    components: {
        'ItemBarcode' : ItemBarcode,
        'ItemLabel' : ItemLabel
    },
    props: {
        productId: String
    },
    data() {
        return {
            itemStocks: [],
            loading: false,
            totalRecords: 0,
            seasons: null,
            rows: this.defaultRows,
            filters: {},
            item: {},
            itemBarcodeDialog: false,
            itemLabelDialog: false,
            params: new URLSearchParams(),
            sortField: String,
            sortOrder: Number,
            itemGQL: 
                `item {
                    id {
                        productId
                        warehouseId
                        colorId
                        sizeId
                    }
                    product {
                        id
                        partnumber
                        category {
                            id 
                            name
                        }
                    }
                    warehouse {
                        id
                        name
                    }
                    color {
                        id
                        name
                    }
                    size {
                        id
                        name
                    }
                    ean
                }
                qtyLoaded 
                qtyUnloaded 
                qtyExisting 
                qtyOrdered 
                qtyAvailable`
        }
    },
    created() {
    },
    mounted() {
        if (this.productId != undefined) {
            this.params.set('id.year', localStorage.getItem('year'));
            this.params.set('item.product.id', this.productId);
            this.filters['id.year'] = localStorage.getItem('year');
            this.filters['item.product..id'] = this.productId;
            this.loading = true;
            this.doSearch(0);
        }
    },
    methods: {
        doSearch(offset) {
            this.loading = true;
            dataService.searchGraphQLAPI('searchItemStocks', this.params, this.itemGQL, offset, this.rows)
            .then(res => {
                this.itemStocks = res.elements;
                this.totalRecords = res.rowCount;
                this.loading = false;
            })
            .catch(error => {
                console.log(error);
                this.loading = false;
                this.$swal({
                    icon: 'error',
                    title:  'Errore',
                    text: error.response?.data.message,
                });               
            });
        },
        onPage(event) {
            this.loading = true;
            this.params = parametersFromEvent(event);
            this.doSearch(event.first)
        },
        filter(event) {
          const dt = this.$refs.dataTable;
          console.log(dt.sortField);
          dt.$emit('filter', dt.createLazyLoadEvent(event));
          dt.resetPage();
        },
        onFilter(event) {
          console.log(event);
          this.loading = true;
          this.params = parametersFromEvent(event);
          this.doSearch(0);
        },
        onSort(event) {
          console.log(event);
          this.loading = true;
          this.params = parametersFromEvent(event);
          this.doSearch(0);
        },
        addBarcode(item) {
            this.item = item;
            this.itemBarcodeDialog = true;
        },
        printLabel(item) {
            this.item = item;
            this.itemLabelDialog = true;
        },
         onItemBarcodeCancel() {
            console.log('CANCELLED!')
        },
         onItemBarcodeSuccess(event) {
            console.log('SUCCESS!');
            console.log(event);
        },
    }
}
</script>