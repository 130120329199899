<template>
    <Dialog ref="itemBarcode" :visible="show" :style="{width: '350px'}" header="Associazione barcode" :modal="true" class="p-fluid" :closable="false" @show="reset()">
         <div style="margin: 0px 0px 30px 0px">
            <div>Magazzino: <b>{{item.warehouse.name}}</b></div>
            <div>Colore: <b>{{item.color.name}}</b></div>
            <div>Taglia: <b>{{item.size.name}}</b></div>
         </div>
         <div class="item.barcode">
            <div class="p-field">
                <span class="p-float-label">
                    <InputText ref="barcode" id="barcode" type="text" v-model="barcode" />
                    <label for="barcode">Barcode</label>
                </span>
            </div>
        </div>
        <template #footer>
            <Button label="Annulla" icon="pi pi-times" class="p-button-text" @click="cancel()"/>
            <Button ref="saveItemButton" label="Salva" icon="pi pi-check" class="p-button-text" @click="addBarcode" />
        </template>
    </Dialog>

   
</template>
<script>
import {dataService} from '../services/DataService';

export default {
    props: {
        item: Object,
        show: Boolean
    },
    emits: ["success", "cancel", "update:show"],
    data() {
        return {
            barcode: ''
        }
    },
    activated() {
        this.barcode = '';
    },
    methods: {
        reset() {
            this.barcode = '';
            console.log(this.$refs.barcode);
            this.$refs.barcode.$el.focus();
        },
        cancel() {
            this.$emit('cancel');
            this.$emit('update:show', false);
        },
        addBarcode() {
            this.$root.loading = true;
            var itemBarcode = {};
            itemBarcode.item = {id : this.item.id};
            itemBarcode.id = this.barcode;
            dataService.storeItemBarcode(itemBarcode)
                .then(res => {
                    this.$root.loading = false;
                    this.$toast.add({severity:'success', summary: 'OK', detail: 'Barcode associato correttamente', life: 5000});
                    this.$emit('update:show', false);
                    this.$emit('success', res);
                })
                .catch(error => {
                    this.$root.loading = false;
                    this.$swal({
                        icon: 'error',
                        title: 'Errore',
                        text: error.response?.data.message,
                    });
                    console.log(error.response?.data.message);
                });
        }
    }
}
</script>