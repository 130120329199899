<template>
  <div class="businesspartner-list">
   
    <h1>{{businessPartnerType == 'F' ? 'Fornitori' : 'Clienti'}}</h1>

    <Toolbar class="p-mb-4">
       <template v-slot:left>
        <Button :label="businessPartnerType == 'F' ? 'Nuovo Fornitore' : 'Nuovo Cliente'" icon="pi pi-plus" class="p-button-success p-mr-2" @click="newBusinessPartner()" />
      </template>
    </Toolbar>

    <Accordion>
      <AccordionTab header="Filtri">
        <form ref="filterForm" @keyup.enter="filter($event)">
          <div class="p-grid p-jc-center p-mt-2">
            <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                    <InputText v-model="filters['lastName']" id="lastName" />
                    <label for="lastName">Nome</label>
                </span>
            </div>
          </div>        
        <Button ref="filterButton" label="Filtra" @click="filter($event)" class="p-button p-col-12" />
        </form>
      </AccordionTab>
    </Accordion>
    
    <DataTable ref="bpDataTable" :value="businessPartners" :lazy="true" :paginator="true" :rows="rows" :autoLayout="true"
      :totalRecords="totalRecords" :filters="filters" :loading="loading" @page="onPage($event)" @sort="onSort($event)"  @filter="onFilter($event)">

      <Column field="id" header="Codice" :sortable="true" filterMatchMode="contains">
      </Column>

      <Column field="lastName" header="Nome" :sortable="true"  filterMatchMode="contains">
        <template #body="slotProps">
            {{slotProps.data.lastName}} {{slotProps.data.firstName}}
        </template>
      </Column>

       <Column field="street" header="Indirizzo" :sortable="true" />

       <Column field="city" header="Città" :sortable="true" />

       <Column field="province" header="Prov" :sortable="true" />

      <Column :exportable="false" bodyClass="p-text-right">
        <template #body="slotProps">
            <Button icon="pi pi-search" class="p-button-rounded p-button-success p-mr-2" @click="editBusinessPartner(slotProps.data)" />
        </template>
    </Column>
      
  </DataTable>

  </div>

</template>

<script>
import {dataService} from '../services/DataService';
import {parametersFromEvent, formatDate, formatAmount} from '../Utils';

export default {
    name: 'BusinessPartnerList',
    props: {
        businessPartnerType : String
    },
    data() {
        console.log('data');
        return {
            loading: false,
            totalRecords: 0,
            businessPartners : null,
            rows: this.defaultRows,
            filters: {},
            sortField: String,
            sortOrder: Number,
            block: false
        }
    },
    created() {
    },
    mounted() {
    },
    //params : new URLSearchParams()
    activated() {
        console.log('activated');
        this.loading = true;
        if (this.businessPartnerType == 'F') {
          dataService.searchSuppliers(this.params, 0, this.rows).then(data => {
              this.totalRecords = data.rowCount,
              this.businessPartners = data.elements
              this.loading = false;
          });   
        } else {
          dataService.searchCustomers(this.params, 0, this.rows).then(data => {
              this.totalRecords = data.rowCount,
              this.businessPartners = data.elements
              this.loading = false;
          }); 
        }
    },
    methods: {
        formatDate,
        formatAmount,
        onPage(event) {
            this.loading = true;
            this.params = parametersFromEvent(event);
            if (this.businessPartnerType == 'F') {
              dataService.searchSuppliers(this.params, event.first, event.rows).then(data => {
                  this.totalRecords = data.rowCount,
                  this.businessPartners = data.elements
                  this.loading = false;
              });
            } else {
              dataService.searchCustomers(this.params, event.first, event.rows).then(data => {
                  this.totalRecords = data.rowCount,
                  this.businessPartners = data.elements
                  this.loading = false;
              });
            }
        },
        filter(event) {
          const dt = this.$refs.bpDataTable;
          console.log(dt.sortField);
          dt.$emit('filter', dt.createLazyLoadEvent(event));
          dt.resetPage();
        },
        onFilter(event) {
          console.log(event);
          this.loading = true;
          this.params = parametersFromEvent(event);
          if (this.businessPartnerType == 'F') {
            dataService.searchSuppliers(this.params, 0, event.rows).then(data => {
                  console.log(data);
                  this.totalRecords = data.rowCount,
                  this.businessPartners = data.elements
                  this.loading = false;
              });
          } else {
            dataService.searchCustomers(this.params, 0, event.rows).then(data => {
                  console.log(data);
                  this.totalRecords = data.rowCount,
                  this.businessPartners = data.elements
                  this.loading = false;
              });
          }
        },
        onSort(event) {
          console.log(event);
          this.loading = true;
          this.params = parametersFromEvent(event);
          if (this.businessPartnerType == 'F') {
            dataService.searchSuppliers(this.params, 0, event.rows).then(data => {
                this.totalRecords = data.rowCount,
                this.businessPartners = data.elements
                this.loading = false;
            });
          } else {
             dataService.searchCustomers(this.params, 0, event.rows).then(data => {
                this.totalRecords = data.rowCount,
                this.businessPartners = data.elements
                this.loading = false;
            });
          }
        },
        newBusinessPartner() {
            console.log('New');
            this.$router.push({ name: 'new-business-partner', params: { businessPartnerType: this.businessPartnerType}});
        },
        editBusinessPartner(bp) {
          //console.log('Edit: ' + bp);
          this.$router.push({ name: 'business-partner', params: { businessPartnerType: this.businessPartnerType, businessPartnerId: bp.id }});
        }
    }
}
/*
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
*/
</script>