<template>
    <div class="document">
        <POSDetail v-if="documentTypeId == '300' || documentTypeId == '310' || documentTypeId == '320'" />
        <DocumentDetail :documentTypeId="$route.params.documentTypeId" :documentNumber="$route.params.documentNumber" v-else />
    </div>
</template>
<script>
//import {dataService} from '../services/DataService';
import DocumentDetail from '../components/DocumentDetail';
import POSDetail from '../components/POSDetail';

export default {
    name : 'Document',
    components: {
        'DocumentDetail' : DocumentDetail,
        'POSDetail' : POSDetail
    },
    data() {
        return {
            document: {},
            items: null
        }
    }, 
    created() {
        this.documentTypeId = this.$route.params.documentTypeId,
        this.documentNumber = this.$route.params.documentNumber
    },
    mounted() {
        //dataService.getDocument(this.documentTypeId, this.documentNumber).then(res => this.document = res);
    }
}
</script>