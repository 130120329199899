<template>
    <div>

        <DataTable ref="dataTable" :loading="dataLoading" :value="items" :lazy="true" :paginator="true" :rows="rows" :autoLayout="true"
            :totalRecords="totalRecords" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" :filters="filters">

            <Column field="document.date" header="Data" :sortable="true" filterMatchMode="equals">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.document.date)}}
                </template>
            </Column>

            <Column field="id.number" header="Numero doc." :sortable="true" filterMatchMode="contains"/>

            <Column field="product.partnumber" header="Codice" :sortable="true" filterMatchMode="contains"/>

            <Column field="product.name" header="Descrizione" :sortable="true" filterMatchMode="contains"/>

            <Column field="warehouse.id" header="Magazzino" :sortable="true" filterMatchMode="exact"/>

            <Column field="color.id" header="Colore" :sortable="true" filterMatchMode="exact">
                <template #body="slotProps">
                    {{slotProps.data.color.id}} - {{slotProps.data.color.name}}
                </template>
            </Column>

             <Column field="size.id" header="Taglia" :sortable="true" filterMatchMode="exact">
                <template #body="slotProps">
                    {{slotProps.data.size.id}}
                </template>
            </Column>
            
            <Column field="quantity" header="Quantità" :sortable="true" />
            
            <Column field="netUnitPrice" header="Prezzo" :sortable="true">
                <template #body="slotProps">
                    {{formatAmount(slotProps.data.netUnitPrice)}}
                </template>
            </Column>

            <Column field="netTotal" header="Totale" :sortable="true">
                <template #body="slotProps">
                    {{formatAmount(slotProps.data.netTotal)}}
                </template>
            </Column>

        </DataTable>

    </div>
</template>
<script>
import {dataService} from '../services/DataService';
import {parametersFromEvent, formatDate, formatAmount} from '../Utils';

export default {
    data() {
        return {
            items: [],
            rows: this.defaultRows,
            totalRecords: 0,
            dataLoading: false,
            filters: {},
            params: new URLSearchParams(),
            currentOffset: 0,
            itemGQL :
            `id {
                documentTypeId
                number
                prog
            }
            document {
                date
            }
            product {
                id
                partnumber
                name
                category {
                    id
                    name
                }
            }
            warehouse {
                id
                name
            }
            color {
                id
                name
            }
            size {
                id
                name
            }
            quantity
            netUnitPrice
            netTotal`
        }
    },
    activated()  {
        this.filters['id.documentTypeId'] = this.$route.params.documentTypeId;
        this.params.set('id.documentTypeId', this.filters['id.documentTypeId']);
        this.params.set('sortProperty', 'document.date|id.number|id.prog');
        this.params.set('sortOrder', 'desc|desc|desc');
        this.doSearch(this.currentOffset);
    },
    methods: {
        formatDate,
        formatAmount,
        doSearch(offset) {
            this.dataLoading = true;
            dataService.searchGraphQLAPI('searchDocumentItems', this.params, this.itemGQL, offset, this.rows)
            .then(res => {
                this.items = res.elements;
                this.totalRecords = res.rowCount;
                this.dataLoading = false;
                this.currentOffset = offset;
            })
            .catch(error => {
                this.dataLoading = false;
                this.$swal({
                    icon: 'error',
                    title:  'Errore',
                    text: error.response?.data.message,
                });               
            });
        },
        onPage(event) {
            this.doSearch(event.first);
        },
        onSort(event) {
          //console.log(event);
          this.params = parametersFromEvent(event);
          this.doSearch(0);
        },
        filter(event) {
          const dt = this.$refs.dataTable;
          dt.$emit('filter', dt.createLazyLoadEvent(event));
          dt.resetPage();
        },
        onFilter(event) {
            this.params = parametersFromEvent(event);
            this.doSearch(0);
        }
    }
}
</script>