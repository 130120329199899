<template>
    <div id="products">
        <ProductStockList :year="year" />
    </div>
</template>
<script>
import ProductStockList from '../components/ProductStockList';
export default {
  name: 'Products',
  components: {
    ProductStockList
  }, 
  data() {
    return {
        year: Number
    }
  },
  created() {
        this.year = Number(localStorage.getItem('year'))
  }
}
</script>