<template>
  <div class="login">
    <Card class="p-mt-4" style="width: 50vw; margin: auto">
      <template v-slot:header>
        Login
      </template>
       <template v-slot:content>
        <form @keyup.enter="login()">
        <div class="p-fluid p-grid">
            <div class="p-field p-col-12">
              <span class="p-float-label">
                <InputText v-model="username"  id="username" required  />
                <label for="username">Username</label>
              </span>
            </div>
            <div class="p-field p-col-12">
              <span class="p-float-label">
                <Password v-model="password" :feedback="false" />
                <label for="password">Password</label>
              </span>
            </div>

        </div>
        </form>
       </template>
      <template v-slot:footer>
        <Button label="Login" class="p-button p-col-12" @click="login()" />
      </template>  
    </Card>
  </div>

</template>

<script>
// eslint-disable-next-line no-unused-vars
import {dataService} from '../services/DataService';

export default {
  name: 'Login',
  data() {
      return {
        username: null,
        password: null
      }
    },
  created() {
        //this.dataService = new DataService();
  },
  mounted() {
    console.log(this.$route);
    this.redir = this.$route.query.redirect
  },
  methods: {
    login() {
      const self = this;
      dataService.login(this.username, this.password)
        .then(data => {
          console.log(data);
          //this.$router.push({name: 'home'});
          console.log(self.redir);
          window.location.href = '/';
        })
        .catch(error => console.log(error));
    }
    
  }
}
</script>
