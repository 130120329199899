<template>
    <Accordion>
        <AccordionTab header="Filtri">
            <form ref="filterForm">
            <div class="p-grid p-jc-center p-mt-2">
                <div class="p-field p-col-12 p-md-2">
                    <span class="p-float-label">
                        <InputText id="partnumber" v-model="filters_['product.partnumber']" />
                        <label for="partnumber">Codice</label>
                    </span>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <span class="p-float-label">
                        <InputText id="name" v-model="filters_['product.name']" />
                        <label for="name">Descrizione</label>
                    </span>
                </div>
                <div class="p-field p-col-12 p-md-1">
                    <span class="p-float-label">
                        <InputText id="color" v-model="filters_['color.id']" />
                        <label for="color">Colore</label>
                    </span>
                </div>
            </div>        
            <Button ref="filterButton" label="Filtra" @click="filter($event)" class="p-button p-col-12" />
            </form>
        </AccordionTab>
    </Accordion>

    

    <DataTable ref="dataTable" :value="value" v-model:expandedRows="expandedRows" dataKey="serializedId" :lazy="true" :paginator="true" :rows="rows" :autoLayout="true"
        :totalRecords="totalRecords" :filters="filters" :loading="loading" @page="onPage($event)" @sort="onSort($event)"  @filter="onFilter($event)" @state-restore="onRestore">
            <Column :expander="true" headerStyle="width: 3rem" />
            <Column field="product.category.name" header="Categoria">
                <template #body="slotProps">
                    {{slotProps.data.product != null ? slotProps.data.product.category.name : "-"}}
                </template>
            </Column>
            <Column field="product.partnumber" header="Codice" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                    <router-link :to="{name: 'product', params: {productId: slotProps.data.product.id}}">
                        {{slotProps.data.product != null ? slotProps.data.product.partnumber : "-"}}
                    </router-link>
                </template>
            </Column>
            <Column field="product.name" header="Descrizione" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                    {{slotProps.data.product != null ? slotProps.data.product.name : "-"}}
                </template>
            </Column>
            
            <Column field="warehouse.name" header="Magazzino" :sortable="true">
                <template #body="slotProps">
                    {{slotProps.data.warehouse != null ? slotProps.data.warehouse.name : "-"}}
                </template>
            </Column>
            
            <Column field="color.id" header="Colore" :sortable="true" filterMatchMode="equals">
                <template #body="slotProps">
                    {{slotProps.data.color != null ? slotProps.data.color.id + ' - ' + slotProps.data.color.name : "-"}}
                </template>
            </Column>

            <Column field="quantity" header="Qtà" headerClass="p-text-center" bodyClass="p-text-center" :sortable="true">
                 <template #body="slotProps">
                    <b>{{slotProps.data.quantity}}</b>
                </template>
            </Column>

            <Column field="quantityProcessed" header="Qtà evasa" headerClass="p-text-center" bodyClass="p-text-center" :sortable="true">
                 <template #body="slotProps">
                    <b>{{slotProps.data.quantityProcessed}}</b>
                </template>
            </Column>

             <Column field="quantityToProcess" header="Qtà da evadere" headerClass="p-text-center" bodyClass="p-text-center" :sortable="true">
                 <template #body="slotProps">
                    <b>{{slotProps.data.quantityToProcess}}</b>
                </template>
            </Column>

            <Column field="netUnitPrice" header="Prezzo" headerClass="p-text-right" bodyClass="p-text-right" :sortable="true">
                <template #body="slotProps">
                    {{formatAmount(slotProps.data.netUnitPrice)}}
                </template>
            </Column>

            <Column field="discountPercentage" header="SC.%" headerClass="p-text-right" bodyClass="p-text-right" :sortable="true">
                <template #body="slotProps">
                    {{slotProps.data.discountPercentage ? slotProps.data.discountPercentage : 0}}
                </template>
            </Column>

            <Column field="total" header="Totale" headerClass="p-text-right" bodyClass="p-text-right" :sortable="true">
                <template #body="slotProps">
                    {{formatAmount(slotProps.data.netTotal)}}
                </template>
            </Column>
            
            <Column bodyClass="p-text-right">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editDocumentItem(slotProps.data)" v-if="isSuperuser || slotProps.data.status != 'X'" />
                    <!--Button icon="pi pi-copy" title="Copia riga" class="p-button-rounded p-button-success p-mr-2" @click="copyDocumentItem(slotProps.data)" v-if="slotProps.data.status != 'X'" /-->
                    <!--Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="deleteDocumentItem(slotProps.data)" v-if="slotProps.data.status != 'X'" /-->
                </template>
            </Column>

            <template #expansion="slotProps">
                
                    <div class="p-grid" style="margin-left: 15%">
                        <div class="col">
                            <div class="main-col">Taglia</div> 
                            <div class="main-col">Ordinato</div>
                            <div class="main-col">Evaso</div>    
                            <div class="main-col">Da evadere</div>    
                        </div>
                        <div class="col" v-for="item in slotProps.data.items" :key="item.prog">
                            <div class="main-col tg-col" style="padding: 5px;">{{item.size.id}}</div> 
                            <div class="sec-col qty-col">{{item.quantity}}</div>    
                            <div class="sec-col qty-col">{{item.quantityProcessed}}</div>    
                            <div class="sec-col qty-col">{{item.quantity-item.quantityProcessed}}</div>    
                        </div> 
                    </div>
                
                <!--
                <div style="width: 100%" class="p-text-right">
                <div style="width: 400px">
                    <DataTable :value="slotProps.data.items" class="p-datatable-gridlines">
                        <Column field="id.prog" header="Prog"></Column>
                        <Column field="size.name" header="Taglia"></Column>
                        <Column field="quantity" header="Quantità"></Column>
                    </DataTable>
                </div>
                </div>
                -->
            </template>

    </DataTable>
</template>
<script>
import {formatDate, formatAmount} from '../Utils';
//import {dataService} from '../services/DataService';

export default {
    props: {
        value: Array,
        rows: Number,
        totalRecords: Number,
        loading: Boolean,
        filters: Object
    },
    watch: { 
        loading: function(newVal, oldVal) {
          console.log('Prop changed: ', newVal, ' | was: ', oldVal)
          if (!newVal) {
              this.expandAll();
          }
        }
    },
    emits: ["page", "sort", "filter", "edit", "copy", "delete", 'update:filters'],
    data() {
        return {
            seasons: null,
            warehouses: null,
            businessPartners : null,
            expandedRows: [],
            filters_: {},
            isSuperuser: false
        }
    },
    mounted() {
        this.isSuperuser = localStorage.getItem('loggedUser') == 'mmancini';
    },
    activated() {
        this.filters_ = this.$props.filters;  
        this.expandAll();
    },
    methods: {
        formatDate,
        formatAmount,
        onPage(event) {
            this.$emit('page', event);
        },
        onSort(event) {
            this.$emit('sort', event);
        },
        filter() {
            this.$emit('update:filters', this.filters_);
            const dt = this.$refs.dataTable;
            dt.$emit('filter', dt.createLazyLoadEvent(event));
            dt.resetPage();
        },
        onFilter(event) {
            event.filters = this.filters_;
            this.$emit('filter', event);
        },
        editDocumentItem(di) {
            this.$emit('edit', di);
        },
        copyDocumentItem(di) {
            this.$emit('copy', di);
        },
        deleteDocumentItem(di) {
            this.$emit('delete', di);
        },
        expandAll() {
            if (this.value)
                this.expandedRows = this.value.filter(p => p.serializedId);
        },
        onRestore(event) {
            console.log("Restored: " + event);
        }
     }
}
</script>