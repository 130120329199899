<template>
    <div>

        <DataTable ref="dataTable" :loading="dataLoading" :value="items" :lazy="true" :paginator="true" :rows="rows" :autoLayout="true"
            :totalRecords="totalRecords" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" :filters="filters">

            <Column field="document.date" header="Data" :sortable="true" filterMatchMode="equals">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.document.date)}}
                </template>
            </Column>

            <Column field="id.number" header="Numero doc." :sortable="true" filterMatchMode="contains"/>

            <Column field="document.documentType.name" header="Tipo documento" :sortable="true" filterMatchMode="contains"/>

            <Column field="warehouse.id" header="Magazzino" :sortable="true" filterMatchMode="exact"/>

            <Column field="color.id" header="Colore" :sortable="true" filterMatchMode="exact">
                <template #body="slotProps">
                    {{slotProps.data.color.id}} - {{slotProps.data.color.name}}
                </template>
            </Column>

             <Column field="size.id" header="Taglia" :sortable="true" filterMatchMode="exact">
                <template #body="slotProps">
                    {{slotProps.data.size.id}}
                </template>
            </Column>
            
            <Column field="quantity" header="Quantità" :sortable="true" />
            
            <Column field="netUnitPrice" header="Prezzo" :sortable="true" :headerClass="isRestricted ? 'hidden' : ''" :bodyClass="(isRestricted ? 'hidden' : '')" :footerClass="isRestricted ? 'hidden' : ''">
                <template #body="slotProps">
                    {{formatAmount(slotProps.data.netUnitPrice)}}
                </template>
            </Column>

            <Column field="netTotal" header="Totale" :sortable="true" :headerClass="isRestricted ? 'hidden' : ''" :bodyClass="(isRestricted ? 'hidden' : '')" :footerClass="isRestricted ? 'hidden' : ''">
                <template #body="slotProps">
                    {{formatAmount(slotProps.data.netTotal)}}
                </template>
            </Column>

            <Column bodyClass="p-text-right">
                <template #body="slotProps">
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteDocumentItem(slotProps.data)" v-if="isSuperuser" />
                </template>
            </Column>

        </DataTable>

    </div>

    <Dialog v-model:visible="deleteDocumentItemDialog" :style="{width: '450px'}" header="Conferma" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            <span v-if="documentItem">Confermi la cancellazione della riga <b>{{documentItem.id.prog}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDocumentItemDialog = false"/>
            <Button label="Sì" icon="pi pi-check" class="p-button-text" @click="deleteDocumentItem" />
        </template>
    </Dialog>


</template>
<script>
import {dataService} from '../services/DataService';
import {parametersFromEvent, formatDate, formatAmount} from '../Utils';

export default {
    props: {
        productId: String,
        year: Number,
    },
    data() {
        return {
            items: [],
            rows: this.defaultRows,
            isSuperuser: false,
            documentItem: null,
            deleteDocumentItemDialog: false,
            isRestricted: true,
            totalRecords: 0,
            dataLoading: false,
            filters: {},
            params: new URLSearchParams(),
            currentOffset: 0,
            itemGQL :
            `id {
                documentTypeId
                number
                prog
            }
            document {
                date
                documentType {
                    id
                    name
                }
            }
            warehouse {
                id
                name
            }
            color {
                id
                name
            }
            size {
                id
                name
            }
            quantity
            netUnitPrice
            netTotal`
        }
    },
    mounted() {
        var profileId = localStorage.getItem('profileId');
        this.isEditable = 'MIRE' == profileId;
        this.isRestricted = 'MIRE' != profileId;
        this.isSuperuser = 'mmancini' == localStorage.getItem('loggedUser');
    },
    activated()  {
        this.filters['document.year'] = this.year;
        this.filters['product.id'] = this.productId;
        this.params.set('document.year', this.filters['document.year']);
        this.params.set('product.id', this.filters['product.id']);
        this.params.set('sortProperty', 'document.date|id.number|id.prog');
        this.params.set('sortOrder', 'desc|desc|desc');
        this.doSearch(this.currentOffset);
    },
    methods: {
        formatDate,
        formatAmount,
        doSearch(offset) {
            this.dataLoading = true;
            console.log('currentOffset: ' + this.currentOffset);
            dataService.searchGraphQLAPI('searchDocumentItems', this.params, this.itemGQL, offset, this.rows)
            .then(res => {
                console.log(res);
                this.items = res.elements;
                this.totalRecords = res.rowCount;
                this.dataLoading = false;
                this.currentOffset = offset;
            })
            .catch(error => {
                 console.log(error);
                this.dataLoading = false;
                this.$swal({
                    icon: 'error',
                    title:  'Errore',
                    text: error.response?.data.message,
                });               
            });
        },
        onPage(event) {
            this.doSearch(event.first);
        },
        onSort(event) {
          //console.log(event);
          this.params = parametersFromEvent(event);
          this.doSearch(0);
        },
        filter(event) {
          const dt = this.$refs.dataTable;
          dt.$emit('filter', dt.createLazyLoadEvent(event));
          dt.resetPage();
        },
        onFilter(event) {
            this.params = parametersFromEvent(event);
            this.doSearch(0);
        },
        confirmDeleteDocumentItem(p) {
            this.documentItem = p;
            this.deleteDocumentItemDialog = true;
        },
        deleteDocumentItem() {
            this.deleteDocumentItemDialog = false;
            this.$root.loading = true;
            dataService.deleteDocumentItem(this.documentItem.id.documentTypeId, this.documentItem.id.number, this.documentItem.id.prog)
                .then(() => {
                    this.$root.loading = false;
                    this.documentItem = {};
                    this.$toast.add({severity:'success', summary: 'OK', detail: 'Riga cancellata', life: 5000});
                    this.doSearch(this.currentOffset);
                }).catch(error => {
                    this.$root.loading = false;
                    this.$swal({
                        icon: 'error',
                        title: 'Errore',
                        text: error.response?.data.message,
                    });
                });
            
            //this.computeTotal();
        }
    }
}
</script>