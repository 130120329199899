<template>
    <Calendar v-model="value_" :showTime="false" :manualInput="manualInput" dateFormat="dd/mm/yy"
             @date-select="onDateSelect" @clear-click="onClearClick" :showButtonBar="showButtonBar" />
</template>

<script>
import {parseDateFromDate, formatDateISO} from '../../Utils';

export default {
    props: {
        modelValue: null,
        showButtonBar: {
            type: Boolean,
            default: false
        },
        manualInput: {
            type: Boolean,
            default: true
        }
    }, 
    watch: { 
        modelValue: function(newVal) {
          if (!this.value_) {
                this.value_ = parseDateFromDate(newVal);
                this.$emit('update:modelValue', newVal);
            }
        }/*,
        value_: function(newVal) {
            this.$emit('update:modelValue', newVal);
        }*/
    },
    emits: ["update:modelValue"],
    data() {
        return {
            value_: null,
            optionList: [],
            params: new URLSearchParams('dateDeleted=*NULL*&sortProperty=name&sortOrder=asc')
        }
    },
    created() {
        this.value_ = parseDateFromDate(this.modelValue);
        this.$emit('update:modelValue', this.value_);
    },
    methods: {
        onDateSelect(e) {
            console.log(e);
            this.$emit('update:modelValue', formatDateISO(e));
        },
        onClearClick() {
            this.$emit('update:modelValue', null);
        }
    }
}
</script>