<template>
    <div id="products">
        <BusinessPartnerList :businessPartnerType="$route.params.businessPartnerType" />
    </div>
</template>
<script>
import BusinessPartnerList from '../components/BusinessPartnerList';
export default {
  name: 'BusinessPartners',
  components: {
    BusinessPartnerList
  }
}
</script>