<template>
    <Toast />
    <div v-if="showMenu">
      <Menubar :model="items">
        <template #start>
          <router-link to="/">
            <img alt="logo" src="./assets/logo.png" style="max-height: 40px; width: auto;" class="p-ml-2 p-mr-2" v-tooltip="'Versione: ' + ver">
          </router-link>
        </template>
        <template #end>
            <span class="p-float-label">
                <Dropdown id="year" v-model="year" :options="years" :showClear="false" @change="onYearChange($event)"/>
            </span>
        </template>
      </Menubar> 
    </div>
   <router-view v-slot="{ Component }">
      <keep-alive >
        <component :is="Component" :key="$route.fullPath" />
      </keep-alive>
    </router-view>

    <Loading :active="isLoading" 
        :can-cancel="false" 
        :is-full-page="true"
        background-color="#000"
        :opacity="0.4"/>

</template>

<script>
import {dataService} from './services/DataService';
import {version} from '../package.json';

import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import { mapState } from "vuex";

export default {
  name: 'App',
  components: {
    'Loading': Loading
  },
  data() {
        return {
            showMenu : false,
//            isLoading: false,
            currentUser: Object,
            items: null,
            years : [],
            year: Number,
            ver: version
        }
    },
    computed: {
      ...mapState("loading", ["isLoading"]),
    },
    mounted() {
      this.year = Number(localStorage.getItem('year'));
      console.log(this.year);
      dataService.isLoggedIn().then(b => this.showMenu = b);
      dataService.loadMenu().then(r => this.items = r);
      dataService.listYears().then(r => {
        this.years = r
        console.log(this.year);
        if (this.year == 0) {
          this.year = this.years[this.years.length - 1];
          localStorage.setItem('year', this.year);
        }
      });
      //this.currentUser = JSON.parse(localStorage.getItem('currentUser')).name;
    },
    methods: {
      onYearChange(event) {
        this.year = event.value;
        localStorage.setItem('year', this.year);
        location.reload();
      },
      logout() {
        console.log('LOGOUT!');
        dataService.logout().then(window.location.href = '/');
      }
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/fontawesome.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/solid.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/brand.min.css';

</style>
