<template>
    <span class="p-float-label">
        <Dropdown v-model="value_" :options="optionList" :filter="(optionList.length > 5)" 
            @show="loadOptions()" @change="onChange($event)" optionLabel="id" :showClear="showClear" >

            <template #value="slotProps">
                <div v-if="slotProps.value">
                    <span v-if="slotProps.value.id">
                        {{slotProps.value.id}} - {{slotProps.value.name}}
                    </span>
                    <span v-else>{{slotProps.value}}</span>
                </div>
                <div v-else>&nbsp;</div>
            </template>
            
            <template #option="slotProps">
                {{slotProps.option?.id}} - {{slotProps.option?.name}}
            </template>
           
        </Dropdown>
        <label>{{label}}</label>
    </span>
</template>
<script>
import {dataService} from '../../services/DataService';

export default {
    props: {
        modelValue: null,
        label: {
            type: String,
            default: 'Stagione'
        },
        showClear: {
            type: Boolean,
            default: false
        },
        optionValue: {
            type: String
        }
    },
    watch: { 
        modelValue: function(newVal) {
          if (!this.value_) {
             this.value_ = newVal;
          }
        }
    },
    emits: ["update:modelValue"],
    data() {
        return {
            value_: null,
            optionList: [],
        }
    },
    activated() {
    },
    methods: {
        loadOptions() {
            if (this.optionList.length == 0)
                dataService.listSeasons().then(data => {this.optionList = data.elements})
        },
        onChange(event) {
            this.$emit('update:modelValue', event.value ? this.optionValue ? event.value[this.optionValue] : event.value : null);
        }
    }
}
</script>