import Axios from 'axios';
import {router} from '../router';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {productStockFields, itemStockFields, documentFields, documentItemFields, productFields} from './GraphQLQueryFields';



class DataService {
    
    constructor() {
        var instance = this; 
        this.authClient = Axios.create({
            baseURL: process.env.VUE_APP_BASEURL,
            headers : {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin' : '*'
            },
            withCredentials: true
        });

        this.apiClient = Axios.create({
            baseURL: process.env.VUE_APP_BASEURL,
            headers : {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin' : '*'
            },withCredentials: true,
        });

        const params = new URLSearchParams();
        params.append('grant_type', 'token');
        const refreshAuthLogic = failedRequest => instance.authClient.post('/auth/refresh', params, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
        .then(tokenRefreshResponse => {
            //localStorage.setItem('token', tokenRefreshResponse.data.token);
            this.bindAuthentication(tokenRefreshResponse.data);
            failedRequest.response.config.headers['XAuth'] = 'Bearer ' + tokenRefreshResponse.data.token;
            return Promise.resolve();
        })
        .catch(e => {
            console.log(e);
            router.push({ name: "login"})
        });

        createAuthRefreshInterceptor(this.apiClient, refreshAuthLogic);
        
        this.apiClient.interceptors.response.use(function (response) {
            return response;
          }, async function (error) {
            console.log(error);
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
            
            return Promise.reject(error);
          });

        /*
        this.apiClient.interceptors.request.use(function (config) {
            //if (store.state.auth.token != null)
                //config.headers.Authorization = 'Bearer '+ store.state.auth.token;
            return config;
          }, function (error) {
            // Do something with request error
            return Promise.reject(error);
          });
        
          this.apiClient.interceptors.response.use(function (response) {
            // Do something with response data
            return response;
          }, async function (error) {
            const status = error.response ? error.response.status : null
            if (status === 401) {
                
                // eslint-disable-next-line no-unused-vars
                return await instance.refreshToken(async _ => {
                    //error.config.headers['Authorization'] = 'Bearer ' +store.state.auth.token;
                    //error.config.baseURL = process.env.VUE_APP_BASEURL;
                    return await instance.apiClient(error.config);
                });
            }
            //console.log(error.config);
            return Promise.reject(error);
          });
          */
    }

    bindAuthentication(data) {
        this.token = data.token;
        localStorage.setItem('warehouse', JSON.stringify(data.user.profile.warehouse));
        localStorage.setItem('saleDocumentType', JSON.stringify(data.user.profile.saleDocumentType));
        localStorage.setItem('profileId', data.user.profile.id);
        localStorage.setItem('loggedUser', data.user.id);
    }

    login(username, password) {
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        params.append('grant_type', 'password');
        return this.authClient.post('/auth/login', params)
        .then(res => {
            console.log(res);
            this.bindAuthentication(res.data);
            return res.data;
        })
        .catch(error => {
            console.log(error);
        });
    }

    async logout() {
        try {
            const res = await this.apiClient.get('/auth/logout');
            if (res.status == 200)
                localStorage.removeItem('currentUser');
        } catch (error) {
            console.log(error);
        }
    }

    async isLoggedIn() {
        try {
            const res = await this.apiClient.get("/api/status");
            console.log(res);
            return res.status == 200;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    async searchGraphQLAPI(operationName, params, fields, first, pageSize) {
        var query = '';
        var i = 0;
        for(var pair of params.entries()) {
            if (i > 0)
                query += ';';
            query += pair[0]+'='+pair[1];
            i++;
         }
         //console.log(query);

        var s = '{'+operationName+'(query: "'+query+'", rowIndex: '+first+', pageSize: '+pageSize+') {rowIndex pageSize rowCount elements {'+fields+'}}}';
        //console.log(s);
        var req = {
            query: s
        };
        const res = await this.apiClient.post('/api/graphql' ,  req);
        //console.log(res);
        return res.data[operationName];
    }

    async searchAPI(uri, params, first, pageSize) {
        if (params == null)
            params = new URLSearchParams();
        if (first != null)
            params.set('rowIndex', first);
        if (pageSize != null)
            params.set('pageSize', pageSize);
        const res = await this.apiClient.get(uri , {params: params});
        return res.data;
    }

    async get(uri) {
        const res = await this.apiClient.get(uri);
        return res.data;
    }

    async post(uri, obj) {
        var s = JSON.stringify(obj);
        console.log(s);
        const res = await this.apiClient.post(uri, s);
        console.log('Risposta');
        console.log(res?.data);
        return res.data;
    }

    async put(uri, obj) {
        var s = JSON.stringify(obj);
        console.log(s);
        const res = await this.apiClient.put(uri, s);
        console.log('Risposta');
        console.log(res?.data);
        return res.data;
    }

    async delete(uri) {
        const res = await this.apiClient.delete(uri);
        return res.data;
    }

    loadMenu() {
        return this.get('/api/menuitems/tree');
    }

    async listYears() {
        const res = await this.apiClient.get('/api/years');
        return res.data;
    }

    searchColors(params, first, pageSize) {
        return this.searchAPI('/api/colors', params, first, pageSize);
    }

    searchSeasons(params, first, pageSize) {
        return this.searchAPI('/api/seasons', params, first, pageSize);
    }

    searchProductTypes(params, first, pageSize) {
        return this.searchAPI('/api/producttypes', params, first, pageSize);
    }

    searchUnitsOfMeasure(params, first, pageSize) {
        return this.searchAPI('/api/unitsofmeasure', params, first, pageSize);
    }

    searchVats(params, first, pageSize) {
        return this.searchAPI('/api/vats', params, first, pageSize);
    }

    searchCategories(params, first, pageSize) {
        return this.searchAPI('/api/categories', params, first, pageSize);
    }

    searchProducts(params, first, pageSize) {
        //return this.searchAPI('/api/products', params, first, pageSize);
        return this.searchGraphQLAPI('searchProducts', params, productFields , first, pageSize);
    }

    searchProductsByTerm(term) {
        return this.searchAPI('/api/products/byterm', new URLSearchParams('term='+term), 0, 10);
    }

    searchCategoriesByTerm(term) {
        return this.searchAPI('/api/categories/byterm', new URLSearchParams('term='+term), 0, 10);
    }


    searchItems(params, first, pageSize) {
        return this.searchAPI('/api/items', params, first, pageSize);
    }

    searchDocumentTypes(params, first, pageSize) {
        return this.searchAPI('/api/documenttypes', params, first, pageSize);
    }

    searchDocuments(params, first, pageSize) {
        //return this.searchAPI('/api/documents', params, first, pageSize);
        return this.searchDocumentsWithGQL(params, first, pageSize);
    }

    searchDocumentsWithGQL(params, first, pageSize) {
        return this.searchGraphQLAPI('searchDocuments',  params, documentFields, first, pageSize);
    }

    searchDocumentItems(params, first, pageSize) {
        return this.searchDocumentItemsWithGQL(params, first, pageSize);
    }

    searchDocumentItemsWithGQL(params, first, pageSize) {
        return this.searchGraphQLAPI('searchDocumentItems',  params, documentItemFields, first, pageSize);
    }

    searchProductStocks(params, first, pageSize) {
        //return this.searchAPI('/api/productstocks?id.year=' + localStorage.getItem('year') , params, first, pageSize);
        return this.searchProductStocksWithGQL(params, first, pageSize);
    }

    searchProductStocksWithGQL(params, first, pageSize) {
        if (params == null)
            params = new URLSearchParams();
        params.set('id.year', localStorage.getItem('year'));
        return this.searchGraphQLAPI('searchProductStocks', params, productStockFields, first, pageSize);
    }

    searchItemStocks(params, first, pageSize) {
        //return this.searchAPI('/api/itemstocks', params, first, pageSize);
        return this.searchItemStocksWithGQL(params, first, pageSize);
    }

    searchItemStocksWithGQL(params, first, pageSize) {
        if (params == null)
            params = new URLSearchParams();
        params.set('id.year', localStorage.getItem('year'));
        return this.searchGraphQLAPI('searchItemStocks', params, itemStockFields, first, pageSize);
    }

    searchWarehouses(params, first, pageSize) {
        return this.searchAPI('/api/warehouses', params, first, pageSize);
    }

    searchCustomers(params, first, pageSize) {
        return this.searchAPI('/api/customers', params, first, pageSize);
    }

    searchSizeScales(params, first, pageSize) {
        return this.searchAPI('/api/sizescales', params, first, pageSize);
    }


    searchSuppliers(params, first, pageSize) {
        return this.searchAPI('/api/suppliers', params, first, pageSize);
    }

    listProductWarehouses(product) {
        return this.searchAPI('/api/products/' + product.id + '/warehouses', new URLSearchParams('sortProperty=id&sortOrder=asc', 0, 1000));
    }

    listProductColors(product) {
        return this.searchAPI('/api/products/' + product.id + '/colors', new URLSearchParams('sortProperty=id&sortOrder=asc', 0, 1000));
    }

    listProductSizes(product) {
        return this.searchAPI('/api/products/' + product.id + '/sizes', new URLSearchParams('sortProperty=id&sortOrder=asc', 0, 1000));
    }

    listProductWarehousesByYear(year, product) {
        return this.searchAPI('/api/products/' + product.id + '/' + year + '/warehouses', new URLSearchParams('sortProperty=id&sortOrder=asc', 0, 1000));
    }

    listProductColorsByYear(year, product) {
        return this.searchAPI('/api/products/' + product.id + '/' + year + '/colors', new URLSearchParams('sortProperty=id&sortOrder=asc', 0, 1000));
    }

    listProductSizesByYear(year, product) {
        return this.searchAPI('/api/products/' + product.id + '/' + year + '/sizes', new URLSearchParams('sortProperty=id&sortOrder=asc', 0, 1000));
    }

    listCategories() {
        return this.searchAPI('/api/categories', new URLSearchParams('sortProperty=id&sortOrder=asc'), 0, 0);
    }

    listVats() {
        return this.searchAPI('/api/vats', new URLSearchParams('sortProperty=id&sortOrder=asc'), 0, 0);
    }

    listProductTypes() {
        return this.searchAPI('/api/producttypes', new URLSearchParams('sortProperty=id&sortOrder=asc'), 0, 0);
    }

    listBrands() {
        return this.searchAPI('/api/brands', new URLSearchParams('sortProperty=name&sortOrder=asc'), 0, 0);
    }

    listSizeScales() {
        return this.searchAPI('/api/sizescales', new URLSearchParams('sortProperty=id&sortOrder=asc'), 0, 0);
    }

    listUnitsOfMeasure() {
        return this.searchAPI('/api/unitsofmeasure', new URLSearchParams('sortProperty=id&sortOrder=asc'), 0, 0);
    }

    listSeasons() {
        return this.searchAPI('/api/seasons', new URLSearchParams('sortProperty=id&sortOrder=desc'), 0, 0);
    }

    listStatuses() {
        return this.searchAPI('/api/documentstatuses', new URLSearchParams('sortProperty=id&sortOrder=desc'), 0, 0);
    }

    listWarehouses() {
        return this.searchAPI('/api/warehouses', new URLSearchParams('sortProperty=id&sortOrder=asc'), 0, 0);
    }

    listColors() {
        return this.searchAPI('/api/colors', new URLSearchParams('sortProperty=id&sortOrder=asc'), 0, 0);
    }

    listCustomers() {
        return this.searchAPI('/api/customers', new URLSearchParams('sortProperty=lastName&sortOrder=asc'), 0, 0);
    }

    listSuppliers() {
        return this.searchAPI('/api/suppliers', new URLSearchParams('sortProperty=lastName&sortOrder=asc'), 0, 0);
    }

    listAppearances() {
        return this.searchAPI('/api/appearances', new URLSearchParams('sortProperty=name&sortOrder=asc'), 0, 0);
    }

    listTransportReasons() {
        return this.searchAPI('/api/transportreasons', new URLSearchParams('sortProperty=name&sortOrder=asc'), 0, 0);
    }

    listTransports() {
        return this.searchAPI('/api/transports', new URLSearchParams('sortProperty=name&sortOrder=asc'), 0, 0);
    }

    listCarriers() {
        return this.searchAPI('/api/carriers', new URLSearchParams('sortProperty=name&sortOrder=asc'), 0, 0);
    }

    listCarriages() {
        return this.searchAPI('/api/carriages', new URLSearchParams('sortProperty=name&sortOrder=asc'), 0, 0);
    }

    listPaymentTypes() {
        return this.searchAPI('/api/paymenttypes', new URLSearchParams('sortProperty=name&sortOrder=asc'), 0, 0);
    }

    listBookmarks() {
        return this.searchAPI('/api/userbookmarks', new URLSearchParams('sortProperty=sequence&sortOrder=asc'), 0, 0);
    }

    listSizesBySizeScale(sizeScaleId) {
        var src = new URLSearchParams('sortProperty=id&sortOrder=asc');
        if (sizeScaleId)
            return this.searchAPI('/api/sizescales/'+sizeScaleId + '/sizes', src, 0, 1000);
        else
            return this.searchAPI('/api/sizes', src, 0, 1000);
    }

    getProduct(productId) {
        return this.apiClient.get("/api/products/" + productId)
                .then(res => res.data);
    }

   
    getItemByBarcode(barcode) {
        return this.apiClient.get("/api/items/ean/" + barcode)
                .then(res => res.data);
    }

    getProductStock(year, productId) {
        return this.apiClient.get("/api/productstocks/" + year + '/' + productId)
                .then(res => res.data);
    }

    getDocumentType(documentTypeId) {
        return this.apiClient.get("/api/documenttypes/" + documentTypeId)
                .then(res => res.data);
    }

    getDocument(documentTypeId, documentNumber) {
        return this.apiClient.get("/api/documents/" + documentTypeId + '/' + documentNumber)
                .then(res => res.data);
    }

    getStockReason(id) {
        return this.apiClient.get("/api/stockreasons/" + id)
                .then(res => res.data);
    }

    getSupplier(id) {
        return this.apiClient.get("/api/suppliers/" + id)
                .then(res => res.data);
    }

    getCustomer(id) {
        return this.apiClient.get("/api/customers/" + id)
                .then(res => res.data);
    }

    getDocumentItem(documentTypeId, documentNumber, prog) {
        return this.apiClient.get("/api/documentitems/" + documentTypeId + '/' + documentNumber + '/' + prog)
                .then(res => res.data);
    }

    getDocumentTotal(documentTypeId, documentNumber, params) {
        return this.apiClient.get("/api/documents/" + documentTypeId + '/' + documentNumber + '/amount/total?' + (params ? params.toString() : ''))
                .then(res => res.data);
    }

    getDocumentTotalQuantity(documentTypeId, documentNumber, params) {
        return this.apiClient.get("/api/documents/" + documentTypeId + '/' + documentNumber + '/quantity/total?' + (params ? params.toString() : ''))
                .then(res => res.data);
    }

    storeProduct(product) {
        return this.post('/api/products', product)
                .then(res => res);
    }

    newDocument(documentTypeId, year) {
        return this.get('/api/documents/' + documentTypeId + '/' + year + '/new').then(res => res);
    }

    storeDocument(document) {
        return this.post('/api/documents', document).then(res => res);
    }

    consolidateDocument(document) {
        return this.post('/api/documents/apply', document).then(res => res);
    }

    storeDocumentItem(documentItem) {
        return this.post('/api/documentitems/safemode', documentItem).then(res => res);
    }

    storeDocumentProduct(documentProduct) {
        return this.post('/api/documentproducts', documentProduct).then(res => res);
    }

    addDocumentItemFromRelated(document, documentItem, quantity) {
        return this.post('/api/documentitems/' + document.id.documentTypeId + '/' + document.id.number + '/addfromrelated?quantity=' + quantity, documentItem).then(res => res);
    }

    storeItemBarcode(itemBarcode) {
        return this.post('/api/itembarcodes', itemBarcode).then(res => res);
    }

    storeBusinessPartner(businessPartnerType, bp) {
        if (businessPartnerType == 'F')
            return this.post('/api/suppliers', bp).then(res => res.data);
        else
            return this.post('/api/customers', bp).then(res => res.data);
    }

    printItemLabel(item, quantity) {
        console.log('ECCOLO!');
        console.log(item);
        return this.post('/api/rcp/labels/'+ item.product.id + '/'+item.id.warehouseId + '/' + item.id.colorId + '/' + item.id.sizeId + '?quantity=' + quantity, null).then(res => res.data);
    }

    printDocumentLabels(document, from, to) {
        return this.post('/api/rcp/labels/'+document.id.documentTypeId + '/' + document.id.number + '?fromProg=' + from + '&toProg=' + to, null).then(res => res.data);
    }

    newDocumentItem(documentTypeId, documentNumber) {
        return this.get('/api/documentitems/' + documentTypeId  + '/' + documentNumber + '/new').then(res => res);
    }

    deleteDocumentItem(documentTypeId, documentNumber, prog) {
        return this.apiClient.delete("/api/documentitems/" + documentTypeId + '/' + documentNumber + '/' + prog)
    }

    sendDocuments(documentTypeId) {
        return this.post('/api/documents/' + documentTypeId + '/send', null).then(res => res);
    }

    newProduct() {
        return this.get('/api/products/new').then(res => res);
    }

}

const dataService = new DataService();
export  {dataService};