<template>
    <div id="bookmarks">
        <DataView :value="bookmarkList" layout="list">
            <template #list="slotProps" >
                <router-link :to="slotProps.data.value">
                    <Card style="width: 200px; height: 200px; margin: 10px">
                        <template #header>
                            <div style="text-align: center;">
                                {{slotProps.data.label}}
                            </div>
                        </template>   
                        <template #content>
                            <div>
                                <div style="font-size: 5em; text-align: center; color: #FFC107">
                                    <i :class="slotProps.data.icon"></i>
                                </div>
                            </div>
                        </template>                 
                    </Card>
                </router-link>
            </template>
        </DataView>
    </div>
</template>
<script>
import {dataService} from '../services/DataService';

export default {
     data() {
        return  {
            bookmarkList: []
        }
     },
     created() {
         dataService.listBookmarks().then(res => this.bookmarkList = res.elements);
     }
}
</script>