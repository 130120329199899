<template>
    <div id="product-detail">
        <div class="p-fluid p-grid">
              <div class="p-field p-col-12 p-md-3">
                <Brand v-model="product.brand" />
                <small class="p-invalid" v-if="submitted && !product.brand">Campo obbligatorio</small>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <span class="p-float-label">
                    <InputText id="partnumber" type="text" v-model="product.partnumber" required :disabled="!isEditable" />
                    <label for="partnumber">Codice</label>
                </span>
                <small class="p-invalid" v-if="submitted && !product.partnumber">Campo obbligatorio</small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <span class="p-float-label">
                    <InputText id="name" type="text" v-model="product.name" :disabled="!isEditable" />
                    <label for="name">Descrizione</label>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <Season v-model="product.season" :showClear="true" />
            </div>
            <div class="p-field p-col-12 p-md-3">
                <ProductType v-model="product.productType" :showClear="true" />
            </div>
            <div class="p-field p-col-12 p-md-2">
                <SizeScale v-model="product.sizeScale" />
                <small class="p-invalid" v-if="submitted && !product.sizeScale">Campo obbligatorio</small>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <UnitOfMeasure v-model="product.unitOfMeasure" />
            </div>
            <div class="p-field p-col-12 p-md-2">
                <Vat v-model="product.vat" />
                <small class="p-invalid" v-if="submitted && !product.vat">Campo obbligatorio</small>
            </div>
             <div class="p-field p-col-12 p-md-2" v-if="!isRestricted">
                <span class="p-float-label">
                   <InputNumber id="purchasePrice" v-model="product.purchasePrice" mode="currency" currency="EUR" locale="it-IT" :disabled="!isEditable" />
                   <label for="price">Prezzo acquisto</label>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-1" v-if="!isRestricted">
                <span class="p-float-label">
                   <InputNumber id="ricarico" v-model="ricarico" v-on:blur="computePrice()" :disabled="!isEditable" />
                   <label for="ricarico">Ric.%</label>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <span class="p-float-label">
                   <InputNumber id="price" v-model="product.price" mode="currency" currency="EUR" locale="it-IT" required v-on:focus="computePrice()" :disabled="!isEditable" />
                   <label for="price">Prezzo vendita</label>
                </span>
                <small class="p-invalid" v-if="submitted && !product.price">Campo obbligatorio</small>
            </div>

            <div class="p-field p-col-12 p-md-3">
                <Category v-model="product.category" :componentKey="product.category" />
                <small class="p-invalid" v-if="submitted && !product.category">Campo obbligatorio</small>
            </div>

          </div>
    </div>

    <Dialog v-model:visible="stockItemDialog" :style="{width: '450px'}" header="Inserisci una variante" :modal="true" class="p-fluid" :closable="false">
        <div class="p-grid p-jc-center p-mt-2">
            <div class="p-field p-col-12 p-md-12">
                <span class="p-float-label">
                    <Dropdown id="warehouse" v-model="itemStock.item.warehouse" :options="warehouseList" :filter="(warehouseList.length > 5)" @show="loadWarehouses()" optionLabel="id">
                        <template #value="slotProps">
                            <div v-if="slotProps.value">
                            {{slotProps.value?.id}} - {{slotProps.value?.name}}
                            </div>
                            <div v-else>&nbsp;</div>
                        </template>
                         <template #option="slotProps">
                            <div v-if="slotProps.option">
                            {{slotProps.option?.id}} - {{slotProps.option?.name}}
                            </div>
                        </template>
                    </Dropdown>    
                    <label for="warehouse">Magazzino</label>
                </span>
                <small class="p-invalid" v-if="itemStockSubmitted && !itemStock.item.warehouse">Campo obbligatorio</small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <span class="p-float-label">
                    <Dropdown id="color" v-model="itemStock.item.color" :options="colorList" :filter="(colorList.length > 5)" @show="loadColors()" optionLabel="id">
                        <template #value="slotProps">
                            <div v-if="slotProps.value">
                            {{slotProps.value?.id}} - {{slotProps.value?.name}}
                            </div>
                            <div v-else>&nbsp;</div>
                        </template>
                         <template #option="slotProps">
                            <div v-if="slotProps.option">
                            {{slotProps.option?.id}} - {{slotProps.option?.name}}
                            </div>
                        </template>
                    </Dropdown>    
                    <label for="color">Colore</label>
                </span>
                <small class="p-invalid" v-if="itemStockSubmitted && !itemStock.item.color">Campo obbligatorio</small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <span class="p-float-label">
                    <Dropdown id="size" v-model="itemStock.item.size" :options="sizeList" :filter="(sizeList.length > 5)" @show="loadSizes()" optionLabel="id">
                        <template #value="slotProps">
                            <div v-if="slotProps.value">
                            {{slotProps.value?.id}} - {{slotProps.value?.name}}
                            </div>
                            <div v-else>&nbsp;</div>
                        </template>
                         <template #option="slotProps">
                            <div v-if="slotProps.option">
                            {{slotProps.option?.id}} - {{slotProps.option?.name}}
                            </div>
                        </template>
                    </Dropdown>    
                    <label for="size">Taglia</label>
                </span>
                <small class="p-invalid" v-if="itemStockSubmitted && !itemStock.item.size">Campo obbligatorio</small>
            </div>
            <div class="p-field p-col-12 p-md-12">
                <span class="p-float-label">
                    <InputText id="ean" v-model="itemStock.ean" />
                    <label for="size">Barcode</label>
                </span>
            </div>
        </div>
        <template #footer>
            <Button label="Salva" icon="pi pi-check" class="p-button-text" @click="saveItemStock()" />
        </template>
    </Dialog>

</template>
<script>
import {dataService} from '../services/DataService';

export default {
    name: 'ProductDetail',
    props: {
    },
    emits: ['productchange', 'saved'],
    data() {
        return  {
            product: {
                itemStocks: []
            }, //this.createDocument(),
            productId: null,
            isNew: true,
            isEditable: false,
            isRestricted: true,
            categoryList : [],
            unitOfMeasureList: [],
            seasonList: [],
            productTypeList: [],
            brandList: [],
            vatList: [],
            sizeScaleList: [],
            warehouseList: [],
            colorList: [],
            sizeList: [],
            submitted: false,
            itemStockSubmitted: false,
            stockItemDialog: false,
            itemStock: null,
            ricarico: 0
        }
    },
    mounted() {
        var profileId = localStorage.getItem('profileId');
        this.isEditable = 'MIRE' == profileId;
        this.isRestricted = 'MIRE' != profileId;
    },
    activated() {
        console.log('activated! --> ' + this.productId);
        this.isNew= true;
        this.productId = this.$route.params.productId;
        const self = this;
        this.ricarico = 0;
        if (this.productId) {
            dataService.getProduct(this.productId).then(data => {
                self.product = data;
                self.$emit('productchange', data);
                self.isNew = false;
            });
        } else {
            this.createProduct();
        }        
        //dataService.listProductTypes().then(data => {this.productTypeList = data.elements; console.log(this.productTypeList.length)})
    },
    methods: {
        createProduct() {
            return dataService.newProduct().then(res => {
                this.product = res;
                this.product.itemStocks = [];
            });
        },
        validate() {
            return true;
        },
        loadWarehouses() {
            if (this.warehouseList.length == 0)
                dataService.listWarehouses().then(data => {this.warehouseList = data.elements})
        },
        loadColors() {
            if (this.colorList.length == 0)
                dataService.listColors().then(data => {this.colorList = data.elements})
        },
        loadSizes() {
            if (this.sizeList.length == 0)
                dataService.listSizesBySizeScale(this.product.sizeScale?.id).then(data => {this.sizeList = data.elements})
        },
        searchProductTypes(event) {
            var src = new URLSearchParams([['id', '%' + event.value + '%']]);
            src.set('sortProperty', 'id');
            src.set('sortOrder', 'asc');
            dataService.searchProductTypes(src, 0, 1000).then(data => this.productTypeList = data.elements);
        },
        searchVats(event) {
            var src = new URLSearchParams([['id', '%' + event.query + '%']]);
            src.set('sortProperty', 'id');
            src.set('sortOrder', 'asc');
            dataService.searchVats(src, 0, 10).then(data => this.vatList = data.elements);
        },
        isValidProduct() {
            var res =  this.product.category
                        && this.product.brand
                        && this.product.vat
                        && this.product.sizeScale
                        && this.product.partnumber
                        && this.product.price
                        ;
            //if (res && this.isNew) {

            // }
            return res;
        },
        isValidItemStock() {
            console.log('isValidItemStock');
            return this.itemStock.item.warehouse
                    && this.itemStock.item.color
                    && this.itemStock.item.size
                    ;
        },
        saveItemStock() {
            this.itemStockSubmitted = true;
            if (this.isValidItemStock()) {
                console.log('saveItemStock');
                this.itemStock.item.product.id = this.product.id;
                this.product.itemStocks.push(this.itemStock);
                this.stockItemDialog = false;
                this.saveProduct();
            }
        },
        saveProduct() {
            this.$root.loading, this.submitted = true;
            if (this.isValidProduct()) {
                
                /*if (this.isNew && this.product.itemStocks.length == 0) {
                    this.itemStock =
                    {
                        item : {
                            product: {
                                id : {}
                            },
                            warehouse: {},
                            color: {},
                            size: {}
                        },
                        id: {
                            year : Number(localStorage.getItem('year'))
                        }
                        
                    };
                    this.stockItemDialog = true;
                } else {*/
                    const self = this;
                    if (!this.isNew)
                        this.product.itemStocks = null;
                    console.log(this.product);
                    dataService.storeProduct(this.product)
                    .then(res => {
                        this.$root.loading, self.submitted = false;
                        this.$toast.add({severity:'success', summary: 'OK', detail: 'Articolo salvato correttamente', life: 5000});
                        this.$emit('saved', res);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$root.loading, self.submitted = false;
                        //this.$toast.add({severity:'error', summary: 'Errore', detail: error, life: 5000});
                        this.$swal({
                            icon: 'error',
                            title: 'Errore',
                            text: error.response?.data.message,
                        });               
                    });
                //}
            } else {
                this.$root.loading = false;
                this.$toast.add({severity:'error', summary: 'Errore validazione', detail: 'Uno o più campi inseriti contengono valori non validi', life: 5000});
            }
        },
        computePrice() {
            console.log(parseFloat(this.ricarico)/parseFloat(100));
            if (parseFloat(this.ricarico) != 0)
                this.product.price = parseFloat(this.product.purchasePrice) + (parseFloat(this.product.purchasePrice)/parseFloat(100)) * (parseFloat(this.ricarico));
        }

    }
}
</script>