<template>
    <h1>{{document?.id?.documentType?.name}}</h1>
    <div id="document-detail" class="p-mt-2">
        <div class="p-fluid p-grid">
            <div class="p-col-12 p-md-8 p-2">
                <Card style="height:100%">
                    <template v-slot:header>
                        Testata documento
                    </template>
                    <template v-slot:content>
                        <div v-if="isNew" class="p-fluid p-grid p-formgrid">
                            <div class="p-field p-col-12 p-md-4">
                                <Calendar v-model="document.date" :locale="it" dateFormat="dd/mm/yy" :manualInput="false" placeholder="Data documento"/>
                            </div>
                        </div>
                        <div v-if="!isNew">Documento: {{document?.id?.number}} del {{formatDate(document?.date)}}</div>
                        <div>Tipo Documento: {{document?.documentType?.id}} - {{document?.documentType?.name}}</div>
                        <div>Magazzino: {{document?.warehouse?.id}} - {{document?.warehouse?.name}}</div>
                    </template>
                </Card>
            </div>
            <div class="p-col-12 p-md-4 p-2">
                <Card style="height: 100%">
                    <template v-slot:header>
                        Importo
                    </template>
                    <template v-slot:content>
                        <h1 class="p-text-right">{{formatAmount(total)}}</h1>
                    </template>
                </Card>
            </div>
         </div>

        <Toolbar class="p-mb-4" v-if="document.status != 'X'">
            <template v-slot:left>
                <Button label="Nuovo articolo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" v-if="document.status != 'X'"/>
            </template>
        </Toolbar>
        
        <DataTable ref="dt" :value="documentItems" :rows="1000" sortField="id.prog" :sortOrder="-1" :autoLayout="true" @filter="onFilter($event)">
            
            <Column field="id.prog" header="N.ro" />
            <Column field="product.category.name" header="Categoria">
                <template #body="slotProps">
                    {{slotProps.data.product != null ? slotProps.data.product.category?.name : "-"}}
                </template>
            </Column>
            <Column field="product.partnumber" header="Codice">
                <template #body="slotProps">
                    <router-link :to="{name: 'productstock', params: {productId: slotProps.data.product.id}}">
                        {{slotProps.data.product != null ? slotProps.data.product.partnumber : "-"}}
                    </router-link>
                </template>
            </Column>
            <Column field="product.name" header="Descrizione">
                <template #body="slotProps">
                    {{slotProps.data.product != null ? slotProps.data.product.name : "-"}}
                </template>
            </Column>
            <Column field="warehouse.name" header="Magazzino">
                <template #body="slotProps">
                    {{slotProps.data.warehouse != null ? slotProps.data.warehouse.name : "-"}}
                </template>
            </Column>
            <Column field="color.id" header="Colore">
                <template #body="slotProps">
                    {{slotProps.data.color != null ? slotProps.data.color.id : "-"}}
                </template>
            </Column>
            <Column field="size.id" header="Taglia">
                <template #body="slotProps">
                    {{slotProps.data.size != null ? slotProps.data.size.id : "-"}}
                </template>
            </Column>
            <Column field="quantity" header="Qtà" headerClass="p-text-center" bodyClass="p-text-center" />
            <Column field="netUnitPrice" header="Prezzo" headerClass="p-text-right" bodyClass="p-text-right">
                 <template #body="slotProps">
                    {{formatAmount(slotProps.data.netUnitPrice)}}
                </template>
            </Column>
            <Column field="total" header="Totale" headerClass="p-text-right" bodyClass="p-text-right">
                 <template #body="slotProps">
                    {{formatAmount(slotProps.data.netTotal)}}
                </template>
            </Column>
            <Column bodyClass="p-text-right">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editDocumentItem(slotProps.data)" v-if="slotProps.data.status !='X'" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteDocumentItem(slotProps.data)" v-if="slotProps.data.status !='X'" />
                </template>
            </Column>
        </DataTable>
    </div>
    <div>
        <div class="action-bar">
            <Button icon="pi pi-arrow-left" label="Indietro" @click="this.$router.go(-1)" />
            <Button icon="pi pi-save" label="Salva" @click="saveDocument" v-if="document.status != 'X'" />
        </div>
    </div>

    <Dialog v-model:visible="documentItemDialog" :style="{width: '450px'}" header="Riga scontrino" :modal="true" class="p-fluid">
        <form v-on:keyup.enter="saveDocumentItem">
        <div class="p-formgrid p-grid" >
            <div class="p-field p-col-12">
                <TabView>
                    <TabPanel header="Codice">
                        <AutoComplete id="product" v-model="documentItem.product" :suggestions="productList" :minLength=2
                            @complete="searchProductsByPartnumber($event)" @item-select="onProductSelect($event)" field="name" :dropdown="true" required>
                            <template #item="slotProps">
                                <div v-if="slotProps.item">
                                {{slotProps.item.category.id}} - {{slotProps.item.partnumber}} - {{slotProps.item.name}}
                                </div>
                            </template>
                        </AutoComplete>
                    </TabPanel>
                     <TabPanel header="Descrizione">
                        <AutoComplete id="product" v-model="documentItem.product" :suggestions="productList" :minLength=2
                            @complete="searchProductsByName($event)" @item-select="onProductSelect($event)" field="name" :dropdown="true" required>
                            <template #item="slotProps">
                                <div v-if="slotProps.item">
                                {{slotProps.item.category.id}} - {{slotProps.item.partnumber}} - {{slotProps.item.name}}
                                </div>
                            </template>
                        </AutoComplete>
                    </TabPanel>
                </TabView>


                
                <small class="p-invalid" v-if="submitted && !documentItem.product">Campo obbligatorio</small>
            </div>
            
            <div class="p-field p-col-12" v-if="documentItem.product?.id">
                <label for="categoryDesc">Categoria: </label>
                <div id="categoryDesc"><b>{{documentItem.product.category.id}} - {{documentItem.product.category.name}}</b></div>
            </div>
            <div class="p-field p-col-12" v-if="documentItem.product?.id">
                <label for="productDesc">Articolo: </label>
                <div id="productDesc"><b>{{documentItem.product.partnumber}} - {{documentItem.product.name}}</b></div>
            </div>
            
            <div class="p-field p-col-4">
                <label for="warehouse">Magazzino <i class="fas fa-redo" @click="loadWarehouses()" style="cursor: pointer"></i></label>
                <Dropdown id="warehouse" v-model="documentItem.warehouse" :options="warehouseList" optionLabel="id" />
                <small class="p-invalid" v-if="submitted && !documentItem.warehouse">Campo obbligatorio</small>
            </div>
            <div class="p-field p-col-4">
                <label for="color">Colore <i class="fas fa-redo" @click="loadColors()" style="cursor: pointer"></i></label>
                <Dropdown id="color" v-model="documentItem.color" :options="colorList" optionLabel="id">
                     <template #option="slotProps">
                        {{slotProps.option.id}} -  {{slotProps.option.name}}
                     </template>
                </Dropdown>
                <small class="p-invalid" v-if="submitted && !documentItem.color">Campo obbligatorio</small>
            </div>
            <div class="p-field p-col-4">
                <label for="size">Taglia <i class="fas fa-redo" @click="loadSizesBySizeScale(documentItem.product?.sizeScale?.id)" style="cursor: pointer"></i></label>
                <Dropdown id="size" v-model="documentItem.size" :options="sizeList" optionLabel="id" />
                <small class="p-invalid" v-if="submitted && !documentItem.size">Campo obbligatorio</small>
            </div>
        </div>

        <div class="p-formgrid p-grid">
             <div class="p-field p-col">
                <label for="quantity">Quantità</label>
                <InputNumber id="quantity" ref="quantity" v-model="documentItem.quantity" />
                <small class="p-invalid" v-if="submitted && !documentItem.quantity">Campo obbligatorio</small>
            </div>
            <div class="p-field p-col">
                <label for="price">Prezzo</label>
                <InputNumber id="price" v-model="documentItem.netUnitPrice" mode="currency" currency="EUR" locale="it-IT" />
                <small class="p-invalid" v-if="submitted && !documentItem.netUnitPrice">Campo obbligatorio</small>
            </div>
        </div>
        </form>

        <template #footer>
            <Button label="Annulla" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button ref="saveItemButton" label="Salva" icon="pi pi-check" class="p-button-text" @click="saveDocumentItem" />
        </template>
       
    </Dialog>

    <Dialog v-model:visible="deleteDocumentItemDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            <span v-if="documentItem">Confermi la cancellazione della riga <b>{{documentItem.id.prog}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDocumentItemDialog = false"/>
            <Button label="Sì" icon="pi pi-check" class="p-button-text" @click="deleteDocumentItem" />
        </template>
    </Dialog>

</template>
<script>
import {dataService} from '../services/DataService';
import {formatAmount, formatDate, it} from '../Utils';
import {store} from "@/stores";

export default {
    name: 'DocumentDetail',
    props: {
        //documentTypeId : String, //this.$route.params.documentTypeId,
        //documentNumber: String //this.$route.params.documentNumber
    },
    data() {
        return  {
            total: 0.0,
            document: {}, //this.createDocument(),
            documentItem : {}, //this.createDocumentItem(),
            documentItems: [],
            //categoryList: null,
            //unitOfMeasureList: null,
            isNew: true,
            documentItemDialog: false,
            deleteDocumentItemDialog: false,
            warehouseList : [],
            colorList: [],
            sizeList: [],
            productList: [],
            it: it,
            submitted: false
        }
    },
    created() {
        this.$barcodeScanner.init(this.onBarcodeScanned)
    },
    mounted() {
    },
    activated() {
        this.documentItemDialog = false;
        this.deleteDocumentItemDialog = false;
        this.isNew= true;
        this.documentTypeId = this.$route.params.documentTypeId;
        this.documentNumber = this.$route.params.documentNumber;
        //console.log("documentNumber: " + this.documentNumber);
        this.documentItems = [];
        //this.loading = true; 
        const self = this;
        if (this.documentTypeId != null && this.documentNumber != undefined) {
            dataService.getDocument(this.documentTypeId, this.documentNumber).then(data => {
                self.document = data;
                self.isNew = false;
                const src = new URLSearchParams();
                src.set('id.documentTypeId', self.document.id.documentTypeId);
                src.set('id.number', self.document.id.number);
                src.set('sortProperty', 'id.prog');
                src.set('sortOrder', 'desc');
                dataService.searchDocumentItems(src, 0, 10000).then(res => {
                    self.documentItems = res.elements != undefined ? res.elements : [];
                    self.computeTotal();
                    this.$root.loading = false;
                })
            });
        } else {
            this.document = this.createDocument();
        }
        //this.documentItem = this.createDocumentItem();
        //dataService.searchWarehouses(new URLSearchParams('sortProperty=id&sortOrder=1'), 0, 1000).then(data => {this.warehouseList = data.elements});
        
    },
    methods: {
        formatAmount,
        formatDate,
        filter(event) {
          const dt = this.$refs.dt;
          dt.$emit('filter', dt.createLazyLoadEvent(event));
          dt.resetPage();
        },
        onFilter(event) {
          console.log(event);
          this.$root.loading = true;
          const src = new URLSearchParams();
            src.set('id.documentTypeId', this.document.id.documentTypeId);
            src.set('id.number', this.document.id.number);
            src.set('sortProperty', 'id.prog');
            src.set('sortOrder', 'desc');
          dataService.searchDocumentItems(src, 0, 10000).then(res => {
                this.documentItems = res.elements != undefined ? res.elements : [];
                this.computeTotal();
                this.$root.loading = false;
            })
        },
        createDocument() {
            this.total = 0;
            this.$root.loading = true;
            return dataService.newDocument(this.documentTypeId, localStorage.getItem('year')).then(res => {
                console.log(res);
                res.warehouse = JSON.parse(localStorage.getItem("warehouse"));
                this.document = res;
                this.document.date = new Date();
                console.log(this.document.date);
                this.$root.loading = false;
            });
        },
        createDocumentItem() {
            this.$root.loading = true;
            return dataService.newDocumentItem(this.documentTypeId, this.document.id.number).then(res => {
                res.quantity = 1;
                //res.warehouse = this.document.warehouse;
                this.documentItem = res;
                this.$root.loading = false;
                this.submitted = false;
                this.warehouseList = [];
                this.colorList = [];
                this.sizeList = [];
                this.productList = [];
                this.documentItemDialog = true;
            })
        },
        createDocumentItemFromItem(item) {
            return dataService.newDocumentItem(this.documentTypeId, this.document.id.number).then(res => {
                console.log('-----------------------------------');
                console.log(res);
                res.quantity = 1;
                res.product = item.product;
                res.warehouse = item.warehouse;
                res.color = item.color;
                res.size = item.size;
                if (item.product.price) {
                    res.netUnitPrice = item.product.price;
                    res.unitPrice = item.product.price;
                }
                this.documentItem = res;
                dataService.listProductWarehousesByYear(this.document.year, this.documentItem.product).then(data => {
                        this.warehouseList = data;
                });
                dataService.listProductColorsByYear(this.document.year, this.documentItem.product).then(data => {
                    this.colorList = data;
                });
                dataService.listProductSizesByYear(this.document.year, this.documentItem.product).then(data => {
                    this.sizeList = data;
                });
                this.productList = [];
                this.$root.loading = false;
                this.documentItemDialog = true;
            })
        },
        computeTotal() {
            dataService.getDocumentTotal(this.document.id.documentTypeId, this.document.id.number)
                .then(res => this.total = res);
        },
        onBarcodeScanned (barcode) {
          console.log(barcode)
          this.$root.loading = true;
          dataService.getItemByBarcode(barcode)
            .then(item => {
                console.log(item);
                this.createDocumentItemFromItem(item);
            })
            .catch(error => {
                this.$root.loading = false;
                //this.$toast.add({severity:'error', summary: 'Errore', detail: error, life: 5000});
                this.$swal({
                    icon: 'error',
                    title: 'Errore',
                    text: error.response?.data.message,
                });
                console.log(error.response?.data.message);
               
            });
          /*
          dataService.searchItems(new URLSearchParams("ean=" + barcode), 0, 1).then(res => {
              console.log(res);
              if (res.elements) {
                var item = res.elements[0];
                console.log(item);
                this.createDocumentItemFromItem(item).then(() => {this.editDocumentItem(this.documentItem)})
              } else {
                  this.$root.loading = false;
                console.log('Nessun articolo trovato');
                this.$toast.add({severity:'error', summary: 'Errore', detail: 'Articolo non trovato!', life: 5000});
              }
          })
          */
        },
        openNew() {
            this.documentItem = this.createDocumentItem();
        },
        editDocumentItem(p) {
            this.submitted = false;
            this.$root.loading = true;
            dataService.getDocumentItem(p.id.documentTypeId, p.id.number, p.id.prog)
                .then(res => {
                    this.documentItem = res;
                    dataService.listProductWarehousesByYear(this.document.year, this.documentItem.product).then(data => {
                        this.warehouseList = data;
                    });
                    dataService.listProductColorsByYear(this.document.year, this.documentItem.product).then(data => {
                        this.colorList = data;
                    });
                    dataService.listProductSizesByYear(this.document.year, this.documentItem.product).then(data => {
                        this.sizeList = data;
                    });
                    this.productList = [];
                    this.$root.loading = false;
                    this.documentItemDialog = true;
                })
                .catch(error => {
                        this.$root.loading = false;
                        this.$swal({
                            icon: 'error',
                            title: 'Errore',
                            text: error.response?.data.message,
                        });
                    });
        },
        searchProducts(event) {
            dataService.searchProductsByTerm(event.query).then(data => this.productList = data);
        },
        searchProductsByPartnumber(event) {
            dataService.searchProducts(new URLSearchParams('partnumber=' + event.query + '%&sortProperty=partnumber&sortOrder=asc'), 0, 50).then(data => this.productList = data.elements);
        },
        searchProductsByName(event) {
            dataService.searchProducts(new URLSearchParams('name=%' + event.query + '%&sortProperty=name&sortOrder=asc'), 0, 50).then(data => this.productList = data.elements);
        },
        onProductSelect(event) {
            console.log(event);
            this.documentItem.warehouse = null;
            this.documentItem.color = null;
            this.documentItem.size = null;
            if (event.value.price) {
                this.documentItem.unitPrice = event.value.price;
                this.documentItem.netUnitPrice = event.value.price;
            } else {
                this.documentItem.netUnitPrice = null;
            }
            dataService.listProductWarehousesByYear(this.document.year, event.value).then(data => {
                this.warehouseList = data;
                if (this.warehouseList.length == 1) {
                    this.documentItem.warehouse = this.warehouseList[0];
                }
            });
            dataService.listProductColorsByYear(this.document.year, event.value).then(data => {
                this.colorList = data;
                if (this.colorList.length == 1) {
                    this.documentItem.color = this.colorList[0];
                }
            });
            dataService.listProductSizesByYear(this.document.year, event.value).then(data => {
                this.sizeList = data;
                if (this.sizeList.length == 1) {
                    this.documentItem.size = this.sizeList[0];
                }
            });
            
        },
        confirmDeleteDocumentItem(p) {
            this.documentItem = p;
            this.deleteDocumentItemDialog = true;
        },
        deleteDocumentItem() {
            this.deleteDocumentItemDialog = false;
            this.$root.loading = true;
            dataService.deleteDocumentItem(this.documentItem.id.documentTypeId, this.documentItem.id.number, this.documentItem.id.prog)
                .then(() => {
                    this.$root.loading = false;
                    this.documentItem = {};
                    this.$toast.add({severity:'success', summary: 'OK', detail: 'Riga cancellata', life: 5000});
                    const dt = this.$refs.dt;
                    dt.$emit('filter', dt.createLazyLoadEvent(event));
                }).catch(error => {
                    this.$root.loading = false;
                    this.$swal({
                        icon: 'error',
                        title: 'Errore',
                        text: error.response?.data.message,
                    });
                });
        },
        hideDialog() {
            this.documentItemDialog = false;
        },
        isValidDocumentItem() {
            return this.documentItem.product
                    && this.documentItem.warehouse
                    && this.documentItem.color
                    && this.documentItem.size
                    && this.documentItem.quantity
                    && this.documentItem.netUnitPrice;
        },
        saveDocumentItem() {
            this.submitted = true;
            if (this.isValidDocumentItem()) {
                store.commit("loading/setLoading", true);
                const self = this;
                dataService.storeDocumentItem(this.documentItem)
                    .then(res => {
                        store.commit("loading/setLoading", false);
                        this.documentItem = res;
                        
                       
                        const dt = this.$refs.dt;
                        dt.$emit('filter', dt.createLazyLoadEvent(event));
                        
                        self.documentItemDialog = false;
                        this.$toast.add({severity:'success', summary: 'OK', detail: 'Riga documento salvata correttamente', life: 5000});
                    })
                    .catch(error => {
                        console.log(error);
                        store.commit("loading/setLoading", false);
                        this.$swal({
                            icon: 'error',
                            title: 'Errore',
                            text: error.response?.data.message,
                        });
                    });
            } else {
                this.$toast.add({severity:'warning', summary: 'Attenzione', detail: 'Compilare tutti i campi obbligatori!', life: 5000});
            }
        },
        saveDocument() {
            this.$root.loading = true;
            var year = Number(localStorage.getItem("year"));
            console.log(year);
            this.document.year =  year;
            
            dataService.consolidateDocument(this.document).then(() => {
                this.$root.loading = false;
                this.$toast.add({severity:'success', summary: 'OK', detail: 'Documento salvato correttamente', life: 5000});
                this.$router.go(-1);
            })
            .catch(error => {
                this.$root.loading = false;
                //this.$toast.add({severity:'error', summary: 'Errore', detail: error, life: 5000});
                this.$swal({
                    icon: 'error',
                    title: 'Errore',
                    text: error.response?.data.message,
                });
                console.log(error.response?.data.message);
               
            });
        },
        loadWarehouses() {
            dataService.listWarehouses().then(res => {this.warehouseList = res.elements})
        },
        loadColors() {
            dataService.listColors().then(res => {this.colorList = res.elements})
        },
        loadSizesBySizeScale(sizeScaleId) {
           dataService.listSizesBySizeScale(sizeScaleId).then(res => {this.sizeList = res.elements});
        }
    }
}

</script>