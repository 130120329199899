<template>
    <h1>{{product ==  null ? 'Nuovo Articolo' : 'Articolo ' + product.category?.id + ' - ' + product.partnumber}}</h1>
    <div class="product">
        <TabView v-model:activeIndex="activeIndex">
            <TabPanel header="Generale">
                <Card>
                    <template v-slot:header>
                        Dati base
                    </template>
                    <template v-slot:content>    
                        <ProductDetail ref="productDetail" @productchange="setProduct" @saved="this.$router.go(-1);" />
                    </template>
                </Card>                

            </TabPanel>
            
            <TabPanel header="Varianti">
                <ItemList :productId="$route.params.productId" :key="componentKey" />
            </TabPanel>

            <TabPanel header="Movimenti">
            </TabPanel>
        </TabView>
        <div>
            <div class="action-bar">
                <Button icon="pi pi-arrow-left" label="Indietro" @click="this.$router.go(-1)" />
                <Button icon="pi pi-save" label="Salva" @click="$refs.productDetail.saveProduct()" v-if="isEditable" />
            </div>
        </div>
    </div>
</template>
<script>
//import {dataService} from '../services/DataService';
import ProductDetail from '../components/ProductDetail';
import ItemList from '../components/ItemList';

export default {
    name : 'Product',
     data() {
        return  {
            product: null,
            activeIndex: 0,
            componentKey: 0,
            isEditable: false,
            isRestricted: true,
        }
     },
    components: {
        'ProductDetail' : ProductDetail,
        'ItemList' : ItemList
    },
    mounted() {
        var profileId = localStorage.getItem('profileId');
        this.isEditable = 'MIRE' == profileId;
        this.isRestricted = 'MIRE' != profileId;
    }, 
    activated() {
        this.activeIndex = 0;
        this.componentKey++;
        this.productId = this.$route.params.productId
    },
    methods: {
        setProduct(p) {
            this.product = p;
        }
    }
}
</script>