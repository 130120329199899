const productStockFields = `
    id {
        producyId
        year
    }
    product {
        id
        partnumber
        category {
            id 
            name
        } 
        name 
        price 
        season {
            id 
            name
        } 
        productType {
            id 
            name
        } 
        brand {
            id
            name
        }
    }
    qtyLoaded 
    qtyUnloaded 
    qtyExisting 
    qtyOrdered 
    qtyAvailable
`;

const itemStockFields = `
    id {
        item {
            id {
                productId
                warehouseId
                colorId
                sizeId
            } 
            product {
                id 
                category {
                    id
                }
                partnumber
            }
            warehouse {
                id
                name
            }
            color {
                id
                name
            }
            size {
                id
                name
            }
            ean
        }
        year
    }
    qtyLoaded 
    qtyUnloaded 
    qtyExisting 
    qtyOrdered 
    qtyAvailable
`;

const documentFields = `
    id {
        documentTypeId
        number
    } 
    documentType {
        id 
        name 
        stockReason {
            id
        }
        protocolType
    } 
    date 
    businessPartnerType
    businessPartner {
        id
        lastName
        firstName
    }
    warehouse {
        id 
        name
    } 
    season {
        id 
        name
    } 
    status
    total
`;

const documentItemFields = `
    id {
        documentTypeId
        number
        prog
    } 
    document {
        id {
            documentTypeId
            number
        }
        documentType {
            id
        }
        date
    }
    parent {
        id {
            documentTypeId
            number
            prog
        }
    }
    status 
    product {
        id 
        partnumber
        category {
            id 
            name
        } 
        name
    } 
    warehouse {
        id 
        name
    } 
    color {
        id 
        name
    } 
    size {
        id 
        name
    } 
    vat {
        id 
        name
        percentage
    } 
    unitOfMeasure {
        id
        name
    }
    quantity 
    discountPercentage
    unitPrice 
    netUnitPrice 
    netTotal 
    taxTotal 
    total
`;

const productFields = `
    id
    partnumber
    name
    category {id name}
    brand {id name}
    vat {id name percentage}
    price
    purchasePrice
    sizeScale {id}
`;

export {
    productStockFields,
    itemStockFields,
    documentFields,
    documentItemFields,
    productFields
}