<template>
    <div id="products">
        <ProductList />
    </div>
</template>
<script>
import ProductList from '../components/ProductList';
export default {
  name: 'Products',
  components: {
    ProductList
  }, 
  data() {
    return {
    }
  },
  created() {}
}
</script>