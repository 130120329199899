<template>
  <div class="document-list">
   
    <h1>Tutti gli articoli</h1>

    <Toolbar class="p-mb-4">
       <template v-slot:left>
        <Button label="Nuovo articolo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="newProduct()" />
      </template>
    </Toolbar>

    <Accordion>
      <AccordionTab header="Filtri">
        <form ref="filterForm" @keyup.enter="filter($event)">
        <div class="p-grid p-jc-center p-mt-2">
          <div class="p-field p-col-12 p-md-2">
              <span class="p-float-label">
                  <InputText v-model="filters['category.name']" id="category" />
                  <label for="category">Categoria</label>
              </span>
          </div>
          <div class="p-field p-col-12 p-md-2">
              <span class="p-float-label">
                  <InputText v-model="filters['partnumber']" id="partnumber" />
                  <label for="partnumber">Codice</label>
              </span>
          </div>
          <div class="p-field p-col-12 p-md-2">
              <span class="p-float-label">
                  <InputText v-model="filters['name']" id="name" />
                  <label for="name">Descrizione</label>
              </span>
          </div>
          <div class="p-field p-col-12 p-md-2">
              <span class="p-float-label">
                  <!--InputText v-model="filters['season.id']" id="season" /-->
                  <Dropdown id="season" v-model="filters['season.id']" :options="seasons" :filter="(seasons.length > 5)" optionLabel="id" optionValue="id" :showClear="true" />
                  <label for="season">Stagione</label>
              </span>
          </div>
          <div class="p-field p-col-12 p-md-2">
              <span class="p-float-label">
                  <!--InputText v-model="filters['season.id']" id="season" /-->
                  <Dropdown id="brand" v-model="filters['brand.id']" :options="brands" optionLabel="name" optionValue="id" :filter="(brands.length > 5)" :showClear="true" />
                  <label for="brand">Marchio</label>
              </span>
          </div>
          <div class="p-field p-col-12 p-md-2">
              <span class="p-float-label">
                  <!--InputText v-model="filters['productType.id']" id="productType" /-->
                  <Dropdown id="productType" v-model="filters['productType.id']" :options="productTypes" :filter="(productTypes.length > 5)" optionLabel="id" optionValue="id" :showClear="true" />
                  <label for="productType">Natura</label>
              </span>
          </div>
        </div>
        <Button ref="filterButton" label="Filtra" @click="filter($event)" class="p-button p-col-12" />
        </form>
      </AccordionTab>
    </Accordion>

     <DataTable ref="productDataTable" :value="products" :lazy="true" :paginator="true" :rows="rows" :autoLayout="true"
      :totalRecords="totalRecords" :filters="filters" :loading="loading"  @page="onPage($event)" @sort="onSort($event)"  @filter="onFilter($event)">

      <Column field="category.name" header="Categoria" :sortable="true"  filterMatchMode="contains">
         <template #loading>
            <span class="loading-text"></span>
        </template>
        <!--template #filter>
            <InputText type="text" v-model="filters['id.category.name']" class="p-column-filter" placeholder="Filtra categoria"/>
        </template-->
      </Column>
      <Column field="partnumber" header="Codice" :sortable="true" filterMatchMode="contains">
        <template #loading>
            <span class="loading-text"></span>
        </template>
      </Column>
      <Column field="name" header="Descrizione" :sortable="true" filterMatchMode="contains">
        <template #loading>
            <span class="loading-text"></span>
        </template>
      </Column>
      <Column field="brand.name" header="Marchio" :sortable="true" filterMatchMode="contains">
        <template #loading>
            <span class="loading-text"></span>
        </template>
        <template #body="slotProps">
            {{slotProps.data.brand?.name}}
        </template>
      </Column>
      <Column field="season.id" header="Stagione" :sortable="true" filterMatchMode="equals">
        <template #body="slotProps">
            {{slotProps.data.season ? slotProps.data.season.id : ''}}
        </template>
        <template #loading>
            <span class="loading-text"></span>
        </template>
      </Column>
      <Column field="unitOfMeasure.id" header="UM" :sortable="true" filterMatchMode="equals">
         <template #body="slotProps">
            {{slotProps.data.unitOfMeasure ? slotProps.data.unitOfMeasure.id + ' - ' + slotProps.data.unitOfMeasure.name : ''}}
        </template>
        <template #loading>
            <span class="loading-text"></span>
        </template>
      </Column>
      <Column field="productType.id" header="Natura" :sortable="true" filterMatchMode="equals">
         <template #body="slotProps">
            {{slotProps.data.productType ? slotProps.data.productType.id : ''}}
        </template>
        <template #loading>
            <span class="loading-text"></span>
        </template>
      </Column>
      <Column field="price" header="Prezzo" :sortable="true">
        <template #body="slotProps">
            {{formatAmount(slotProps.data.price)}}
        </template>
        <template #loading>
            <span class="loading-text"></span>
        </template>
      </Column>
      <Column :exportable="false" bodyClass="p-text-right">
        <template #body="slotProps">
            <Button icon="pi pi-search" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" />
            <!--Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" /-->
        </template>
       </Column>
       
  </DataTable>

  </div>

</template>

<script>
import {dataService} from '../services/DataService';
import {parametersFromEvent, formatAmount} from '../Utils';

export default {
    name: 'ProductList',
    props: {
    },
    data() {
        return {
            loading: false,
            totalRecords: 0,
            products: null,
            seasons: [],
            productTypes: [],
            brands: [],
            rows: this.defaultRows,
            filters: {},
            params: new URLSearchParams(),
            sortField: String,
            sortOrder: Number,
            currentOffset: 0,
            itemGQL : 
            `id 
            partnumber
            name
            brand {
                id
                name
            }
            category {
                id
                name
            }
            productType {
                id
                name
            }
            season {
                id
                name
            }
            unitOfMeasure {
                id
                name
            }
            vat {
                id
                name
            }
            price`
        }
    },
    created() {
        dataService.listSeasons().then(data => {
            this.seasons = data.elements
        });
        dataService.listProductTypes().then(data => {
            this.productTypes = data.elements
        });
        dataService.listBrands().then(data => {
            this.brands = data.rowCount > 0 ? data.elements : [];
        });
    },
    //params : new URLSearchParams()
    activated() {        
        this.doSearch(this.currentOffset);
    },
    methods: {
        formatAmount,
        doSearch(offset) {
            this.loading = true;
            dataService.searchGraphQLAPI('searchProducts', this.params, this.itemGQL, offset, this.rows)
            .then(res => {
                this.products = res.rowCount > 0 ? res.elements : [];
                this.totalRecords = res.rowCount;
                this.loading = false;
                this.currentOffset = offset;
            })
            .catch(error => {
                console.log(error);
                this.loading = false;
                this.$swal({
                    icon: 'error',
                    title:  'Errore',
                    text: error.response?.data.message,
                });               
            });
        },
        onPage(event) {
            this.params = parametersFromEvent(event);
            this.doSearch(event.first)
        },
        filter(event) {
          const dt = this.$refs.productDataTable;
          console.log(dt.sortField);
          dt.$emit('filter', dt.createLazyLoadEvent(event));
          dt.resetPage();
        },
        onFilter(event) {
          console.log(event);
          this.params = parametersFromEvent(event);
          this.doSearch(0);
        },
        onSort(event) {
          console.log(event);
          this.params = parametersFromEvent(event);
          this.doSearch(0);
        },
        newProduct() {
          this.$router.push({ name: 'newProduct'});
        },
        editProduct(p) {
          this.$router.push({ name: 'product', params: { productId: p.id }});
        }
    }
}
</script>