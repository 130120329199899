<template>
    <Dialog ref="itemquantity" :visible="show" :style="{width: '350px'}" header="Stampa etichette" :modal="true" class="p-fluid" :closable="false" @show="reset()">
         <div style="margin: 0px 0px 30px 0px">
            <div>Magazzino: <b>{{item.warehouse.name}}</b></div>
            <div>Colore: <b>{{item.color.name}}</b></div>
            <div>Taglia: <b>{{item.size.name}}</b></div>
         </div>
         <div class="item.quantity">
            <div class="p-field">
                <span class="p-float-label">
                    <InputText ref="quantity" id="quantity" type="text" v-model="quantity" />
                    <label for="quantity">Quantità</label>
                </span>
            </div>
        </div>
        <template #footer>
            <Button label="Annulla" icon="pi pi-times" class="p-button-text" @click="cancel()"/>
            <Button ref="saveItemButton" label="Stampa" icon="pi pi-print" class="p-button-text" @click="sendToPrinter" />
        </template>
    </Dialog>

   
</template>
<script>
import {dataService} from '../services/DataService';

export default {
    props: {
        item: Object,
        show: Boolean
    },
    emits: ["success", "cancel", "update:show"],
    data() {
        return {
            quantity: ''
        }
    },
    activated() {
        this.quantity = '';
    },
    methods: {
        reset() {
            this.quantity = '';
            console.log(this.$refs.quantity);
            this.$refs.quantity.$el.focus();
        },
        cancel() {
            this.$emit('cancel');
            this.$emit('update:show', false);
        },
        sendToPrinter() {
            this.$root.loading = true;
            
            dataService.printItemLabel(this.item, this.quantity)
                .then(() => {
                    this.$root.loading = false;
                    this.$toast.add({severity:'success', summary: 'OK', detail: 'Etichette inviate in stampa', life: 5000});
                    this.$emit('update:show', false);
                    this.$emit('success');
                })
                .catch(error => {
                    this.$root.loading = false;
                    this.$swal({
                        icon: 'error',
                        title: 'Errore',
                        text: error.response?.data.message,
                    });
                    console.log(error.response?.data.message);
                });
        }
    }
}
</script>