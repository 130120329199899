<template>
    <div id="business-partner-detail">
        <div class="p-fluid p-grid p-mt-4">
            <div class="p-col-12 p-lg-6">
                <Card>
                    <template v-slot:header>
                        Dati anagafici
                    </template>
                    <template v-slot:content>   

                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <span class="p-float-label">
                                <InputText v-model="bp.lastName" id="lastName" />
                                <label for="lastName">Cognome</label>
                                </span>
                                <small class="p-invalid" v-if="submitted && !bp.lastName">Campo obbligatorio</small>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <span class="p-float-label">
                                <InputText v-model="bp.firstName" id="firstName" />
                                <label for="firstName">Nome</label>
                                </span>
                            </div>
                            
                            <div class="p-field p-col-12">
                                <span class="p-float-label">
                                <InputText v-model="bp.street" id="street" />
                                <label for="street">Indirizzo</label>
                                </span>
                            </div>


                            <div class="p-field p-col-12 p-md-2">
                                <span class="p-float-label">
                                <InputText v-model="bp.zipCode" id="zip" />
                                <label for="zip">C.A.P.</label>
                                </span>
                            </div>
                            <div class="p-field p-col-12 p-md-7">
                                <span class="p-float-label">
                                <InputText v-model="bp.city" id="city" />
                                <label for="city">Città</label>
                                </span>
                            </div>
                            <div class="p-field p-col-12 p-md-1">
                                <span class="p-float-label">
                                <InputText v-model="bp.province" id="province" />
                                <label for="province">Prov.</label>
                                </span>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <Country v-model="bp.country.id" optionValue="id" />
                                <small class="p-invalid" v-if="submitted && !bp.country.id">Campo obbligatorio</small>
                            </div>                       
                            
                            
                        </div>

                    </template>
                </Card>
            </div>

             <div class="p-col-12 p-lg-6">
                <Card>
                    <template v-slot:header>
                        Dati fiscali
                    </template>
                    <template v-slot:content>   
                        <div class="p-fluid p-grid">

                            <div class="p-field p-col-12 p-md-6">
                                <span class="p-float-label">
                                <InputText v-model="bp.vatCode" id="vatCode" />
                                <label for="vatCode">Partita IVA</label>
                                </span>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <span class="p-float-label">
                                <InputText v-model="bp.fiscalCode" id="fiscalCode" />
                                <label for="fiscalCode">Codice Fiscale</label>
                                </span>
                            </div>

                            <div class="p-field p-col-12 p-md-3">
                                <span class="p-float-label">
                                <InputText v-model="bp.eInvoiceCode" id="sdi" />
                                <label for="sdi">Codice Destinatario</label>
                                </span>
                            </div>
                            <div class="p-field p-col-12 p-md-9">
                                <span class="p-float-label">
                                <InputText v-model="bp.pec" id="pec" />
                                <label for="pec">PEC</label>
                                </span>
                            </div>

                        </div>
                    </template>
                </Card>
             </div>  
        </div>

        
    </div>

     <div class="action-bar">
        <Button icon="pi pi-arrow-left" label="Indietro" @click="this.$router.go(-1)" />
        <Button icon="pi pi-save" label="Salva" @click="saveBusinessPartner" />
    </div>

</template>
<script>
import {dataService} from '../services/DataService';

export default {
    name: 'DocumentDetail',
    props: [
        'businessPartnerType', 'businessPartnerId'
    ],
    data() {
        return  {
            bp : {country: {}},
            submitted: false,
        }
    },
    emits: ["entitychange"],
    activated() {
        if (this.businessPartnerId != undefined) {
            if (this.businessPartnerType == 'F') {
                dataService.getSupplier(this.businessPartnerId).then(res => {
                    this.bp = res;
                    if (!this.bp.country)
                        this.bp.country = {};
                    this.$emit('entitychange', this.bp);
                });
            } else {
                dataService.getCustomer(this.businessPartnerId).then(res => {
                    this.bp = res;
                     if (!this.bp.country)
                        this.bp.country = {};
                    this.$emit('entitychange', this.bp);
                });
            }
        } else {
            this.bp = {country: {}};
            this.$emit('entitychange', null);
        }
    },
    methods: {
        isValid() {
            return this.bp.lastName;
        },
        saveBusinessPartner() {
            this.submitted = true;
            this.$root.loading = true;
            dataService.storeBusinessPartner(this.businessPartnerType, this.bp).then(() => {
                this.$root.loading = false;
                this.$toast.add({severity:'success', summary: 'OK', detail: (this.businessPartnerType == 'F' ? 'Fornitore' : 'Cliente') + ' salvato correttamente', life: 5000});
                this.$router.go(-1);
            })
            .catch(error => {
                this.$root.loading = false;
                //this.$toast.add({severity:'error', summary: 'Errore', detail: error, life: 5000});
                this.$swal({
                    icon: 'error',
                    title: 'Errore',
                    text: error.response?.data.message,
                });
                console.log(error.response?.data.message);
               
            });
                       
        }
    }
}
</script>