<template>
    <Accordion>
      <AccordionTab header="Filtri">
        <form ref="filterForm" @keyup.enter="filter($event)">
          <div class="p-grid p-jc-center p-mt-2">
            <div class="p-field p-col-12 p-md-3" v-show="docTypeDisplay != 'hidden'">
                <span class="p-float-label">
                    <Dropdown id="documentType" v-model="filters_['id.documentTypeId']" :options="documentTypes" 
                        optionLabel="name" optionValue="id" :showClear="true"/>
                    <label for="documentType">Tipo documento</label>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                    <InputText v-model="filters_['id.number']" id="number" />
                    <label for="number">Numero</label>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <!--
                <span class="p-float-label">
                    <Dropdown id="bp" v-model="filters_['businessPartner.lastName']" :filter="true" @show="loadBusinessPartners()" :options="businessPartners" optionLabel="lastName" optionValue="lastName" :showClear="true">
                        
                    </Dropdown>
                    <label for="bp">{{bpType}}</label>
                </span>
                -->
                <Customer v-model="filters_['businessPartner.lastName']" optionValue="lastName" :showClear="true" v-if="bpType == 'Cliente'" />
                <Supplier v-model="filters_['businessPartner.lastName']" optionValue="lastName" :showClear="true" v-else />
                
            </div>
            <div class="p-field p-col-12 p-md-2">
                <!--
                <span class="p-float-label">
                    <Dropdown id="season" v-model="filters_['season.id']" :options="seasons" optionLabel="id" optionValue="id" :showClear="true" />
                    <label for="season">Stagione</label>
                </span>
                -->
                <Season v-model="filters_['season.id']" optionValue="id" :showClear="true" />
            </div>
            <div class="p-field p-col-12 p-md-2">
                <!--
                <span class="p-float-label">
                    <Dropdown id="warehouse" v-model="filters['warehouse.id']" :options="warehouses" optionLabel="id" optionValue="id" :showClear="true" />
                    <label for="warehouse">Magazzino</label>
                </span>
                -->
                <Warehouse v-model="filters_['warehouse.id']" optionValue="id" :showClear="true" />
            </div>
          </div>

        
        <Button ref="filterButton" label="Filtra" @click="filter($event)" class="p-button p-col-12" />
        </form>
      </AccordionTab>
    </Accordion>

    <DataTable ref="documentDataTable" :value="value" :lazy="true" :paginator="true" :rows="rows" :autoLayout="true"
      :totalRecords="totalRecords" :filters="filters" :loading="loading" 
      @page="onPage($event)" @sort="onSort($event)"  @filter="onFilter($event)">

      
      <Column field="id.documentTypeId" header="Tipo documento" :sortable="true" filterMatchMode="equals" :headerClass="docTypeDisplay" :bodyClass="docTypeDisplay" :footerClass="docTypeDisplay">
         <template #body="slotProps">
            {{slotProps.data.documentType.name}}
        </template>
      </Column>
    
      <Column field="id.number" header="Numero" :sortable="true" filterMatchMode="contains" />

      <Column field="date" header="Data" :sortable="true"  filterMatchMode="equals">
         <template #body="slotProps">
            {{formatDate(slotProps.data.date)}}
        </template>
      </Column>

      <Column field="status" header="Stato" :sortable="true" filterMatchMode="equals" />

      <Column field="businessPartner.lastName" :header="bpType" :sortable="true"  filterMatchMode="equals">
         <template #body="slotProps">
            {{slotProps.data.businessPartner != null ? slotProps.data.businessPartner.lastName : ''}}
        </template>
      </Column>

      <Column field="warehouse.id" header="Magazzino" :sortable="true"  filterMatchMode="equals">
        <template #body="slotProps">
            {{slotProps.data.warehouse != null ? slotProps.data.warehouse.id : ''}}
        </template>
      </Column>

      <Column field="season.id" header="Stagione" :sortable="true"  filterMatchMode="equals">
        <template #body="slotProps">
            {{slotProps.data.season != null ? slotProps.data.season.id : ''}}
        </template>
      </Column>

      <Column field="total" header="Importo" :sortable="true" headerClass="p-text-right" bodyClass="p-text-right" >
         <template #body="slotProps">
            {{formatAmount(slotProps.data.total)}}
        </template>
      </Column>
      
        <Column>
            <template #body="slotProps">
                {{this.countDraftItems(slotProps.data)}}
                <i v-if="slotProps.data.draftCount > 0" class="fas fa-exclamation-triangle" v-tooltip="'Attenzione! Il documento contiene righe non salvate.'"></i>
            </template>
        </Column>

      <Column :exportable="false" bodyClass="p-text-right">
        <template #body="slotProps">
            <Button icon="pi pi-search" class="p-button-rounded p-button-success p-mr-2" @click="editDocument(slotProps.data)" />
            <!--Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" /-->
        </template>
    </Column>
       
  </DataTable>
</template>
<script>
import {formatDate, formatAmount} from '../Utils';
import {dataService} from '../services/DataService';

export default {
    props: {
        value: Array,
        documentTypes: Array,
        rows: Number,
        totalRecords: Number,
        loading: Boolean,
        bpType: String,
        filters: Object
    },
    watch: { 
        documentTypes: function(newVal) {
           this.docTypeDisplay = newVal?.length == 1 ? 'hidden' : '';
        },
    },
    emits: ["page", "sort", "filter", "editdocument", 'update:filters'],
    data() {
        return {
            seasons: null,
            warehouses: null,
            businessPartners : null,
            filters_: {},
            docTypeDisplay: ''
        }
    },
    mounted() {
        console.log('Document types', this.documentTypes);
        this.filters_ = this.$props.filters;  
        dataService.searchSeasons(new URLSearchParams('sortProperty=id&sortOrder=asc'), 0, 1000).then(data => {
            this.seasons = data.elements
        });
        dataService.searchWarehouses(new URLSearchParams('sortProperty=id&sortOrder=asc'), 0, 1000).then(data => {
            this.warehouses = data.elements
        });
        
    },
    activated() {
        
    },
    methods: {
        formatDate,
        formatAmount,
        loadBusinessPartners() {
            if (!this.businessPartners) {
                var src =new URLSearchParams('sortProperty=lastName&sortOrder=asc');
                if (this.documentTypes[0].businessPartnerType == 'C')
                    dataService.searchCustomers(src, 0, 1000).then(data => this.businessPartners = data.elements);
                else if (this.documentTypes[0].businessPartnerType == 'F')
                    dataService.searchSuppliers(src, 0, 1000).then(data => this.businessPartners = data.elements); 
            }
        },
        countDraftItems(doc) {
            var params = new URLSearchParams();
            params.set('id.documentTypeId', doc.id.documentTypeId);
            params.set('id.number', doc.id.number);
            params.set('status', 'N');
            dataService.searchGraphQLAPI('searchDocumentItems', params, 'id {prog}', 0, 0)
                .then(res => {
                    console.log(res);
                    doc.draftCount = res.rowCount;
                    
                });
        },
        onPage(event) {
            this.$emit('page', event);
        },
        onSort(event) {
            this.$emit('sort', event);
        },
        filter() {
            this.$emit('update:filters', this.filters_);
            const dt = this.$refs.documentDataTable;
            dt.$emit('filter', dt.createLazyLoadEvent(event));
            dt.resetPage();
        },
        onFilter(event) {
          this.$emit('filter', event);
        },
        editDocument(doc) {
            this.$emit('editdocument', doc);
        }
     }
}
</script>