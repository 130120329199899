import moment from 'moment';

function parametersFromEvent(event) {
    var res = new URLSearchParams();
    console.log(event.sortField);
    if (event.sortField != null)
        res.set('sortProperty', event.sortField);
    if (event.sortOrder != null)
        res.set('sortOrder', event.sortOrder > 0 ? 'asc' : 'desc');
    if (event.filters != null) {
        for (const f in event.filters) {
            const matchMode = event.filterMatchModes[f];
            const val = event.filters[f];
            if (val != '' && val != null) {
                switch(matchMode) {
                    case 'contains':
                        res.set(f, '%'+val+'%');
                        break;
                    case 'startsWith':
                        res.set(f, val+'%');
                        break;
                    case 'endsWith':
                        res.set(f, '%' + val);
                        break;
                    case 'equals':
                        res.set(f, val);
                        break;
                    case 'notEquals':
                        res.set('!'+f, val);
                        break;
                    case 'lt':
                        res.set('<'+f, val);
                        break;
                    case 'lte':
                        res.set('<='+f, val);
                        break;
                    case 'gt':
                        res.set('>'+f, val);
                        break;
                    case 'gte':
                        res.set('>='+f, val);
                        break;
                    default:
                        res.set(f, val);
                } 
            } else {
                res.delete(f);
            }
        }
    }
    return res;
}

function queryFromEvent(event) {
    var res = ''
    if (event.sortField != null)
        res += 'sortProperty=' + event.sortField +',';
    if (event.sortOrder != null)
        res += 'sortOrder=' + event.sortOrder > 0 ? 'asc' : 'desc' +',';
    if (event.filters != null) {
        for (const f in event.filters) {
            const matchMode = event.filterMatchModes[f];
            const val = event.filters[f];
            if (val != '' && val != null) {
                switch(matchMode) {
                    case 'contains':
                        res += f + '=%'+val+'%,';
                        break;
                    case 'startsWith':
                        res += f + '=' + val+'%,';
                        break;
                    case 'endsWith':
                        res += f + '=%' + val +',';
                        break;
                    case 'equals':
                        res += f + '=' + val;
                        break;
                    case 'notEquals':
                        res += '!'+f +'=' + val +',';
                        break;
                    case 'lt':
                        res += '<'+f + '=' + val +',';
                        break;
                    case 'lte':
                        res += '<='+f + '=' + val +',';
                        break;
                    case 'gt':
                        res += '>'+f + '=' + val +',';
                        break;
                    case 'gte':
                        res += '>'+f + '=' + val +',';
                        break;
                } 
            } else {
                //res.delete(f);
            }
        }
    }
    return res;
}

function parseDate(value) {
    if (value) {
        return moment(String(value)).toDate();
    }
    return null;
}

function formatDate(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
      }
}

function parseDateFromDate(value) {
    if (value) {
        return new Date(value); //moment(String(value)).format('DD/MM/YYYY')
      }
}

function formatAmount(value) {
    if (value != null) {
        return (value).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
        });
    }
}

const it = {
    firstDayOfWeek: 1,
    dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    dayNamesMin: ["Do","Lu","Ma","Me","Gi","Ve","Sa"],
    monthNames: [ "Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre" ],
    monthNamesShort: [ "Gen", "Feb", "Mar", "Apr", "Mag", "Giu","Lug", "Ago", "Set", "Ott", "Nov", "Dic" ],
    today: 'Oggi',
    clear: 'Pulisci',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Set'
}

function formatDateISO(value) {
    if (value) {
        var res = moment(value).format('YYYY-MM-DD');
        return res;
    }
}

export {
    parametersFromEvent,
    queryFromEvent,
    parseDate,
    formatAmount,
    formatDate,
    formatDateISO,
    parseDateFromDate,
    it
}