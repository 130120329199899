<template>
    <div id="product-stock">
        <div class="p-fluid p-grid p-jc-center">

            <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                    <label id="qtyLoaded" class="p-inputwrapper-filled">{{productStock.qtyLoaded}}</label>
                    <label for="qtyLoaded">Qtà Caricata</label>
                </span>
            </div>

            <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                    <label id="qtyUnloaded" class="p-inputwrapper-filled">{{productStock.qtyUnloaded}}</label>
                    <label for="qtyUnloaded">Qtà Scaricata</label>
                </span>
            </div>

            <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                    <label id="qtyExisting" class="p-inputwrapper-filled">{{productStock.qtyExisting}}</label>
                    <label for="qtyExisting">Giacenza</label>
                </span>
            </div>

            <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                    <label id="qtyAvailable" class="p-inputwrapper-filled">{{productStock.qtyAvailable}}</label>
                    <label for="qtyAvailable">Qtà Disponibile</label>
                </span>
            </div>

            <div class="p-field p-col-12 p-md-2">
                <span class="p-float-label">
                    <label id="qtyOrdered" class="p-inputwrapper-filled">{{productStock.qtyOrdered}}</label>
                    <label for="qtyOrdered">In Arrivo</label>
                </span>
            </div>

        </div>
    </div>
</template>
<script>
import {dataService} from '../services/DataService';

export default {
    name: 'ProductStock',
    props: {
        productId: String,
        year : Number
    },
    data() {
        return {
            loading: false,
            productStock: {}
        }
    },
    activated() {
        this.loading = true;
        dataService.getProductStock(this.year, this.productId)
            .then(res => {
                this.productStock = res;
                this.loading = false;
            });
    }
}
</script>