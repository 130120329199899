<template>
    <Dialog ref="itemquantity" :visible="show" :style="{width: '450px'}" header="Stampa etichette" :modal="true" class="p-fluid" :closable="false" @show="reset()">
         
        <p>Verranno inviate alla stampante <b>{{count}}</b> etichette.<br/>Vuoi continuare?</p>
         
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="cancel()"/>
            <Button ref="saveItemButton" label="Si" icon="pi pi-print" class="p-button-text" @click="sendToPrinter" />
        </template>
    </Dialog>

   
</template>
<script>
import {dataService} from '../services/DataService';

export default {
    props: {
        document: Object,
        show: Boolean
    },
    emits: ["success", "cancel", "update:show"],
    data() {
        return {
            from: '0000',
            to: '9999',
            count: 0
        }
    },
    activated() {
    },
    methods: {
        reset() {
            dataService.getDocumentTotalQuantity(this.document.id.documentTypeId, this.document.id.number)
                .then(res => this.count = res);
            /*this.from = '';
            this.to = '';
            this.$refs.from.$el.focus();
            */
        },
        cancel() {
            this.$emit('cancel');
            this.$emit('update:show', false);
        },
        sendToPrinter() {
            this.$root.loading = true;
            
            dataService.printDocumentLabels(this.document, this.from, this.to)
                .then(() => {
                    this.$root.loading = false;
                    this.$toast.add({severity:'success', summary: 'OK', detail: 'Etichette inviate in stampa', life: 5000});
                    this.$emit('update:show', false);
                    this.$emit('success');
                })
                .catch(error => {
                    this.$root.loading = false;
                    this.$swal({
                        icon: 'error',
                        title: 'Errore',
                        text: error.response?.data.message,
                    });
                    console.log(error.response?.data.message);
                });
        }
    }
}
</script>